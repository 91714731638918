import { useState } from "react";
import { ClientInviteClass } from "src/classes/models/client_invite";
import Button from "src/components/Button";
import LoaderPage from "src/components/Loader/LoaderPage";
import Table from "src/components/Tables/Table";
import { deleteInvite } from "src/network/client_invite";
import { useAppSelector } from "src/redux/store";
import { ClientInvite } from "src/types/models/ClientInvite";
import { dateFormat1 } from "src/utils/dates/formats";
import { customToast } from "src/utils/toast";
import { ReactComponent as NoResults } from "src/assets/svg/no-result.svg";

const Users = ({
  invites,
  setInvites
}: {
  invites?: ClientInvite[];
  setInvites: (_: ClientInvite[]) => void;
}) => {
  const is_admin = useAppSelector((r) => r.client.client?.is_admin);
  const [deleting, setDeleting] = useState(0);
  if (!invites) return <LoaderPage />;

  const delet = async (id) => {
    setDeleting(id);
    try {
      await deleteInvite(id);
      customToast({ message: "Invite removed", type: "success" });
      setInvites(invites.filter((i) => i.id !== id));
    } catch {
      customToast({ message: "Error Removing invite" });
    }
    setDeleting(0);
  };

  if (!invites.length) return <Empty />;

  return (
    <Table
      data={invites}
      heads={["Name", "Email Address", "Status", "Date Added", "Action"]}
      row={(i) => {
        const { id, first_name, last_name, email, User } = i as ClientInvite;
        const I = new ClientInviteClass(i);
        const active = I.getStatus() === "Active";
        return [
          <p>{User?.full_name || first_name + " " + last_name}</p>,
          <p>{email}</p>,
          <p className={`${active && "text-[#26993F]"}`}>{active ? `• Active` : "Invited"}</p>,
          <p>{User?.Client?.createdAt ? dateFormat1(User.Client.createdAt) : "-"}</p>,
          active || !is_admin ? (
            <p className="ml-10">-</p>
          ) : (
            <Button
              onClick={() => delet(id)}
              disabled={!!deleting && deleting !== id}
              loading={deleting === id}
              className="!py-2 !px-4 !w-[92px]"
              variant="white_red"
              shapeVariant={2}
            >
              Remove
            </Button>
          )
        ];
      }}
    />
  );
};

const Empty = () => (
  <div className=" flex flex-col items-center mx-auto mt-[100px] w-fit">
    <NoResults className="w-[200px] h-[200px]" />
    <p className="font-poppins text-2xl font-semibold mt-4">There are no invited recruiters yet</p>
    <p className="font-[500] font-poppins text-base text-[#8A8A8A] mt-[10px]">
      It will be available here once you will start inviting recruiters.
    </p>
  </div>
);

export default Users;
