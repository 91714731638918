import { useState } from "react";
import Input, { IInput } from "../Input/Input";

export type range = [number | undefined, number | undefined];
export interface IRangeBoxes {
  value?: range;
  onChange?: (r: range) => void;
  className?: string;
  input1?: IInput;
  input2?: IInput;
}

const RangeBoxes = ({ value, onChange, className = "", input1, input2 }: IRangeBoxes) => {
  const [_value, setValue] = useState(value);

  const _onChange = (value: range) => {
    setValue(value);
    onChange?.(value);
  };

  return (
    <div className={"w-full flex gap-[22px] items-center " + className}>
      <Input
        placeholder="Enter Min"
        {...input1}
        type="number"
        value={_value?.[0]?.toString()}
        className={input1?.className || "!w-[220px]"}
        onChange={(e) => _onChange([Number(e.target.value), _value?.[1]])}
      />
      <p className="font-poppins text-sm">to</p>
      <Input
        placeholder="Enter Max"
        {...input2}
        type="number"
        value={_value?.[1]?.toString()}
        className={input2?.className || "!w-[220px]"}
        onChange={(e) => _onChange([_value?.[0], Number(e.target.value)])}
      />
    </div>
  );
};

export default RangeBoxes;
