import { useState } from "react";
import Button from "src/components/Button";
import Layout from "src/router/AuthenticatedRouter/Layout";
import useLoader from "src/hooks/useLoader";
import { bulkInvite } from "src/network/interview";
import Step1, { Invite } from "./Step1";
import Step2 from "./Step2";
import { customToast } from "src/utils/toast";
import useSlugParam from "src/hooks/useSlug";

const Steps = [Step1, Step2];

const JobInvitePage = () => {
  const [invites, setInvites] = useState<Invite[]>([]);
  const { id, slug } = useSlugParam();
  const [step, setStep] = useState(0);
  const onSubmit = async () => {
    const { already_invited, invited } = await bulkInvite({
      data: invites,
      job_id: Number(id)
    });

    if (!invited.length) {
      customToast({ message: "All the provided emails were already invited" });
      return;
    }

    if (already_invited.length) {
      customToast(
        {
          message:
            "The following emails were already invited: " +
            already_invited.map((i) => "\n" + i.email)
        },
        { duration: 50000 }
      );
    }

    setStep(1);
  };
  const { ldng, loading } = useLoader(onSubmit, { errorMsg: "Error occured sending invites" });

  const Step = Steps[step];

  return (
    <Layout
      title="Confirm the emails to invite"
      back={"/job/" + slug}
      rightChildren={
        !step ? (
          <Button
            disabled={!invites.length}
            onClick={ldng}
            loading={loading}
            className="!rounded-3xl !py-4 !px-6 !h-fit"
          >
            Next
          </Button>
        ) : undefined
      }
    >
      <div className="p-9 rounded-[8px] border-[0.2px] border-[#A9ACB1] bg-white min-h-full">
        <Step onInvites={setInvites} />
      </div>
    </Layout>
  );
};

export default JobInvitePage;
