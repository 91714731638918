import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "src/components/Button";
import { usDialCodeOption } from "src/components/Form/DropdownSelect/Options";
import { FormInput } from "src/components/Form/Input/FormInput";
import { FormPhoneInput } from "src/components/Form/PhoneInput/FormPhoneInput";
import { IPhone } from "src/components/Form/PhoneInput/PhoneInput";
import Logo from "src/components/Logo/Logo";
import useLoader from "src/hooks/useLoader";
import { clientMemberSignUp } from "src/network/auth";
import { IMemberSignUp } from "src/network/auth/type";
import { login } from "src/redux/slices/auth";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { noSpaceAtStartNorEndRegex } from "src/utils/constraints";
import { customToast } from "src/utils/toast";

const MemberSignUpPage = () => {
  const { invites } = useAppSelector((r) => r.auth);
  if (!invites) {
    customToast({ message: "Invites not found" });
    throw new Error("Invalid access to member invite page");
  }
  const [confirmed, setConfirmed] = useState(false);

  let Comp = <Form onBack={() => setConfirmed(false)} />;
  if (!confirmed) Comp = <Banner setConfirmed={() => setConfirmed(true)} />;
  return (
    <div className="w-full py-10 flex flex-col items-center justify-center gap-12">
      <Logo className="w-[140px] " />
      {Comp}
    </div>
  );
};

const Banner = ({ setConfirmed }) => {
  const { invites } = useAppSelector((r) => r.auth);
  const invite = invites?.[0];
  if (!invite) throw new Error();
  return (
    <>
      <p className="font-poppins font-semibold text-2xl">
        Join <span className="text-odfblue">{invite.Company.name}</span> Recruiting Team on
        Outdefine
      </p>
      <div
        className="w-[548px] px-[82px] py-12 bg-white rounded-[20px] border-[1px] border-odfblue
     flex flex-col items-center gap-9"
      >
        <p className="font-poppins text-xl font-semibold max-w-[380px] leading-[36px] text-center">
          You have been invited by{" "}
          <span className="text-odfblue">{invite.Company.Admin?.User.first_name}</span> to join the{" "}
          <span className="text-odfblue">{invite.Company.name}</span> Recruiting Team.
        </p>
        <Button shapeVariant={2} onClick={setConfirmed}>
          Accept Invite
        </Button>
      </div>
    </>
  );
};

const defaultValues = {
  // User
  role: "",
  first_name: "",
  last_name: "",
  phone: { dial: usDialCodeOption } as IPhone
};

const Form = ({ onBack }: { onBack: () => void }) => {
  const { invites } = useAppSelector((r) => r.auth);
  const invite = invites?.[0];
  if (!invite) throw new Error();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });
  const base = { control, errors };
  const submit = async (data: typeof defaultValues) => {
    const _data: IMemberSignUp = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone.phone || "",
      dial_code: data.phone.dial?.value || "",
      role: data.role,
      company_id: invite.company_id
    };
    await clientMemberSignUp(_data);
    await dispatch(login());
  };
  const { ldng, loading } = useLoader(submit);
  const className = "mb-9";

  return (
    <>
      <p className="font-poppins text-2xl font-semibold mb-9">Tell us about yourself</p>
      <form
        onSubmit={handleSubmit(ldng)}
        className="w-[548px] bg-white rounded-3xl p-6 border-[1px] border-odfblue"
      >
        <Label>First Name*</Label>
        <FormInput
          {...base}
          name="first_name"
          className={className}
          input={{
            placeholder: "Enter your first name"
          }}
          rules={{
            required: "Field is required",
            minLength: {
              value: 2,
              message: "First name must be at least 2 characters"
            },
            validate: (value) => noSpaceAtStartNorEndRegex.test(value) || "Invalid whitespace usage"
          }}
        />

        <Label>Last Name*</Label>
        <FormInput
          {...base}
          name="last_name"
          className={className}
          input={{
            placeholder: "Enter your last name"
          }}
          rules={{
            required: "Field is required",
            minLength: {
              value: 2,
              message: "Last name must be at least 2 characters"
            },
            validate: (value) => noSpaceAtStartNorEndRegex.test(value) || "Invalid whitespace usage"
          }}
        />

        <Label>Role at Company*</Label>
        <FormInput
          {...base}
          name="role"
          className={className}
          input={{
            placeholder: "Enter your role at company"
          }}
          rules={{
            required: "Role is required",
            minLength: {
              value: 2,
              message: "must be at least 2 characters"
            },
            validate: (value) => noSpaceAtStartNorEndRegex.test(value) || "Invalid whitespace usage"
          }}
        />

        <Label>Phone Number*</Label>
        <FormPhoneInput {...base} required name="phone" className="mb-6" />

        <div className="w-fit ml-auto flex gap-6">
          <Button
            onClick={onBack}
            disabled={loading}
            variant="white_blue"
            className="!w-fit h-[56px] !rounded-3xl !px-6"
          >
            Back
          </Button>
          <Button
            loading={loading}
            type="submit"
            className="!w-[107px] h-[56px] !rounded-3xl !px-6"
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

const Label = ({ children }) => (
  <p className="font-poppins text-base font-semibold mb-3">{children}</p>
);

export default MemberSignUpPage;
