import { useNavigate } from "react-router-dom";
import { CountCard } from "../../components/CountCard";
import Empty from "../../components/Empty";
import usePagination from "src/hooks/usePagination";
import { Interview } from "src/types";
import { getInterviewsPaginated } from "src/network/interview";
import PaginatedDiv from "src/components/PaginatedDiv";
import Table from "src/components/Tables/Table";
import Button from "src/components/Button";
import { dateFormat1 } from "src/utils/dates/formats";
import InterviewStatusTag from "../../components/InterviewStatusTag";
import { InterviewMetrics } from "src/network/interview/type";
import LoaderPage from "src/components/Loader/LoaderPage";
import useSlugParam from "src/hooks/useSlug";
import InitialsLogo from "src/components/InitialsLogo";

const InterviewedTalent = ({ metrics }: { metrics?: InterviewMetrics }) => {
  const navigate = useNavigate();
  const { id, slug } = useSlugParam();
  const { data, count, fetching, fetch } = usePagination<Interview>({
    fetchPage: (d) =>
      getInterviewsPaginated({
        ...d,
        job_id: id,
        sort: [["ai_score", "ASC NULLS LAST"]]
      }),
    page_size: 10
  });

  return (
    <>
      <div className="flex gap-12 mb-6">
        <CountCard title="Passed" count={metrics?.PASSED} />
        <CountCard title="Pending" count={metrics?.INVITED} />
      </div>

      {!data && <LoaderPage />}

      {count === 0 && (
        <Empty
          title="There are no interviewed talents"
          text="Once talents start taking interviews, they will show up here."
          button={{ onClick: () => navigate("/job/invite/" + slug), label: "Invite Talent" }}
        />
      )}

      {data && !!count && (
        <PaginatedDiv
          className="!h-auto flex-1 "
          onLimit={fetch}
          loading={fetching}
          count={count}
          currentCount={data?.length}
        >
          <Table
            data={data}
            heads={[
              "Rank",
              "Name",
              "Email Address",
              "Recruiter",
              "Score",
              "Status",
              "Date Taken",
              "Action"
            ]}
            row={(i, index) => {
              const { email, date_completed, first_name, last_name, ai_score, slug, Inviter } =
                i as Interview;
              return [
                <p className="max-w-[18px] mx-auto">#{index + 1}</p>,
                <p>{`${first_name} ${last_name}`}</p>,
                <p className="max-w-[207px] overflow-hidden text-ellipsis">{email}</p>,
                <InitialsLogo user={Inviter.User} />,
                <p className="max-w-[30px] overflow-hidden text-ellipsis">{ai_score || "-"}</p>,
                <InterviewStatusTag i={i} />,
                <p>{date_completed ? dateFormat1(date_completed) : "-"}</p>,
                <Button
                  onClick={() => navigate("/job/interview/" + slug)}
                  disabled={!date_completed}
                  className="!text-sm !rounded-3xl !w-[141px] !px-4"
                >
                  View Insights
                </Button>
              ];
            }}
          />
        </PaginatedDiv>
      )}
    </>
  );
};

export default InterviewedTalent;
