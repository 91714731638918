import Logo from "src/components/Logo/Logo";
import Interview from "src/assets/images/screenshots/iainterview.png";
import Waves from "src/assets/images/generic/waves.png";
import Sound from "src/assets/images/generic/sound.png";
import { Link } from "react-router-dom";

const Layout = ({ children }) => {
  return (
    <div className="w-fit mx-auto px-[36px] pt-[46px] flex gap-[60px]">
      {/* Left side */}
      <div className="w-[708px] rounded-3xl bg-lighter-gray p-6 pb-0 overflow-hidden">
        <div className="w-full flex justify-between items-center pr-[10px]">
          <Link to="/">
            <Logo className="w-[195px] p-4" />
          </Link>
          <img src={Sound} className="w-[76px] h-[36px]" />
        </div>
        <p className="max-w-[500px] font-poppins font-bold text-[32px] leading-[64px] mt-12">
          Meet <span className="text-odfblue">Debbie</span>, your{" "}
          <span className="text-odfblue">AI powered recruiter. </span>
        </p>

        <p className="max-w-[600px] mt-4 mb-12 font-poppins text-inactive-gray text-2xl leading-[48px]">
          She has learned from millions of candidate interviews to become the world's best
          recruiter. Debbie works 24x7, helping you recruit and scale faster.
        </p>

        <img src={Interview} className="relative w-full h-auto z-10 " />
        <img src={Waves} className="w-[756px] min-w-[756px]  opacity-15 h-auto -mt-[160px] -ml-6" />
      </div>

      {/* Right side */}
      {children}
    </div>
  );
};

export default Layout;
