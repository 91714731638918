import { interviewStatus } from "src/constants";
import { Interview } from "src/types";

const styles = {
  INVITED: "text-[#FF914D] bg-[rgba(255,145,77,0.05)]",
  FAILED: "text-coral-red bg-coral-red-hue",
  PASSED: "text-success bg-[rgba(38,153,63,0.05)]"
};

const InterviewStatusTag = ({ i }: { i: Interview }) => {
  return (
    <p
      className={
        "p-[10px] rounded-[8px] font-poppins text-sm font-[500] w-fit text " + styles[i.status]
      }
    >
      {interviewStatus(i)}
    </p>
  );
};

export default InterviewStatusTag;
