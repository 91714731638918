import Spinner from "./Spinner";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant?: keyof typeof variants;
  shapeVariant?: keyof typeof shapeVariants;
}

const Button = ({
  type = "button",
  variant = "primary",
  shapeVariant = 1,
  className,
  loading,
  disabled,
  children,
  ...props
}: IButton) => {
  return (
    <button
      className={`group flex items-center justify-center font-poppins font-semibold
      disabled:cursor-not-allowed disabled:opacity-80 ${variants[variant]} ${shapeVariants[shapeVariant]} ${className}`}
      type={type}
      disabled={disabled || loading}
      data-cy="button"
      {...props}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
};

const variants = {
  primary: "text-white bg-odfblue hover:bg-odf-hue1 disabled:bg-[#8A8A8A]",
  white:
    "text-black bg-white hover:bg-placeholder disabled:bg-white border-[1px] border-light-gray ",
  white_blue:
    "text-odfblue bg-white hover:bg-placeholder disabled:bg-white border-[1px] border-odfblue ",
  light_red: "text-error bg-[#fcf4f4] hover:bg-coral-red hover:text-[#fcf4f4]   ",
  light_blue:
    "text-odfblue bg-[rgba(95,95,255,0.05)] hover:bg-odfblue hover:text-white border-[1px] border-odfblue  ",
  transparent_blue:
    "text-odfblue bg-none hover:bg-odfblue hover:text-white border-[1px] border-odfblue  ",
  white_red: "text-error bg-white hover:bg-placeholder disabled:bg-white border-[1px] border-error "
};

const shapeVariants = {
  1: "rounded-lg px-10 py-2 h-10  w-full ",
  2: "rounded-3xl py-4 px-6 h-fit "
};

export default Button;
