import { useEffect } from "react";
import { useAppDispatch } from "src/redux/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from "src/redux/slices/auth";
import { customToast } from "src/utils/toast";
import { confirmSignIn, signIn } from "aws-amplify/auth";
import LoaderPage from "src/components/Loader/LoaderPage";

const authErrors = {
  UserNotConfirmedException: "Your account is not verified.",
  NotAuthorizedException: "Link is invalid or it expired",
  UserNotFoundException: "You have not signed up yet. Create your account first."
};

// The purpose of this view is to analyze the current magic link in order to sign in the user
const MagicLinkPage = () => {
  const [params] = useSearchParams();
  const token = params.get("token") || "";
  const email = params.get("email") || "";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const _login = async () => {
    try {
      const { nextStep } = await signIn({
        username: email,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP"
        }
      });
      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
        // to send the answer of the custom challenge
        await confirmSignIn({ challengeResponse: token });
        await dispatch(login());
      }
    } catch (e: any) {
      customToast({ message: authErrors[e.name] || e.message });
    }
    navigate("/");
  };

  // Allow to change tab from url
  useEffect(() => {
    _login();
  }, [token]);

  return <LoaderPage className="!h-screen" />;
};

export default MagicLinkPage;
