import Select from "react-select";
import {
  Option,
  countriesOptions,
  currencyOptions,
  dialCodeOptions,
  experienceOptions,
  nmbOfEmplOptions,
  periodOptions
} from "./Options";

type Options =
  | "countries"
  | "number_of_employees"
  | "dial_codes"
  | "experiences"
  | "periods"
  | "currencies";
interface IDropdownSelect extends Omit<React.ComponentProps<typeof Select>, "onChange"> {
  _options?: Options;
  onChange?: (opt: Option) => void; // To fix the annoying unknown type on the default onChange signature
  controlClassName?: string;
}

const OPTIONS: { [key in Options]: typeof countriesOptions } = {
  countries: countriesOptions,
  number_of_employees: nmbOfEmplOptions,
  dial_codes: dialCodeOptions,
  experiences: experienceOptions,
  periods: periodOptions,
  currencies: currencyOptions
};

const DropdownSelect = ({
  className,
  controlClassName,
  _options,
  onChange,
  classNames,
  ...props
}: IDropdownSelect) => {
  if (_options) props.options = OPTIONS[_options];
  return (
    <div className={className}>
      <Select
        classNames={{
          control: () =>
            ` px-1 h-[48px] !rounded-[8px] !border-dark-gray !font-poppins text-sm ${controlClassName}`,
          ...classNames
        }}
        {...props}
        onChange={onChange as any}
      />
    </div>
  );
};

export default DropdownSelect;
