import { ReactNode } from "react";

interface ITable<T> {
  className?: string;
  heads: ReactNode[];
  data: T[];
  row: (_: T, i: number) => ReactNode[];
}

const Table = ({ className, data, row, heads }: ITable<any>) => {
  return (
    <table className={"w-full " + className}>
      <thead className=" text-sm font-poppins font-semibold bg-[#D9D9FF] ">
        <tr className="[&>th]:text-start [&>*:first-child]:pl-4  [&>*:first-child]:rounded-l-xl [&>*:last-child]:rounded-r-xl ">
          {heads.map((h, i) => (
            <th className="py-2 " key={i}>
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((d, i) => (
          <tr key={i} className="border-[#8A8A8A]  border-b-[1px]">
            {row(d, i).map((Elem, i) => (
              <td key={i}>
                <div className="py-[30px] font-poppins text-sm text-black font-[500]">{Elem}</div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
