import FormController, { IFormController } from "../FormController";
import { IInput } from "../Input/Input";
import EmailInput from "./EmailInput";

interface IFormEmailInput extends IFormController {
  input?: IInput;
}

export const FormEmailInput = ({ input, ...props }: IFormEmailInput) => {
  return <FormController Children={EmailInput} childrenProps={input} {...props} />;
};
