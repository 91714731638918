import { Controller, ControllerProps, FieldErrors } from "react-hook-form";
import FormErrorSpan from "./FormErrorSpan";

export interface IFormController extends Omit<ControllerProps, "render"> {
  Children?: any;
  childrenProps?: any;
  control: any;
  errors?: FieldErrors<any>;
  className?: string;
}

const FormController = ({
  Children,
  childrenProps,
  className,
  errors,
  name,
  ...props
}: IFormController) => {
  const error = errors?.[name]?.message || "";
  return (
    <div className={className}>
      <Controller
        name={name}
        {...props}
        render={({ field: { onChange, value } }) => (
          <Children onChange={onChange} value={value} {...childrenProps} />
        )}
      />
      {error && <FormErrorSpan text={error as string} />}
    </div>
  );
};

export default FormController;
