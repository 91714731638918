import { Interview } from "src/types";
import { ReactComponent as NoFile } from "src/assets/svg/no-file.svg";

const Resume = ({ interview }: { interview: Interview }) => {
  const { resume_presigned, resume } = interview;
  if (!resume_presigned || !resume)
    return (
      <div className="mx-auto flex flex-col items-center gap-6 pt-[120px]">
        <NoFile className="w-[220px] h-[220px]" />
        <p className="font-poppins text-xl font-semibold">No Resume Found</p>
      </div>
    );

  // If we need to render a doc we use Microsft api, else google api
  const isPdf = resume?.endsWith(".pdf");

  const iframe_src = isPdf
    ? resume_presigned
    : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(resume_presigned)}`;

  return (
    <div className="mt-9">
      <iframe className="w-[841px] rounded-2xl h-screen" src={iframe_src} />
    </div>
  );
};

export default Resume;
