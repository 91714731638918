import { useState } from "react";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import { Option } from "../DropdownSelect/Options";
import { numericRegex } from "src/utils/constraints";

export type IPhone = {
  dial?: Option;
  phone?: string;
};

export interface IPhoneInput {
  value: IPhone;
  onChange?: (phone: IPhone) => void;
  className?: string;
}

const PhoneInput = ({ value, onChange, className }: IPhoneInput) => {
  const [_value, setValue] = useState(value);

  // Handlers

  // Phone input handler
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = { ..._value, phone: e.target.value };
    // Ignore not numeric inputs
    if (newValue.phone && !numericRegex.test(newValue.phone)) return;
    setValue(newValue);
    onChange?.(newValue);
  };

  // Dial handler
  const _onSelect = (dial: Option) => {
    const newValue = { ..._value, dial };
    setValue(newValue);
    onChange?.(newValue);
  };
  return (
    <div className={"flex !font-poppins text-sm  " + className}>
      <DropdownSelect
        value={_value?.dial}
        placeholder="US +1"
        className="shrink-0"
        _options="dial_codes"
        classNames={{
          control: () => " !w-[96px] h-[48px] !rounded-l-[8px] !border-dark-gray !rounded-r-[0px] ",
          indicatorSeparator: () => "hidden",
          dropdownIndicator: () => " !pl-0",
          singleValue: () => " !text-clip "
        }}
        onChange={_onSelect}
      />
      <input
        placeholder="Ex.4567891"
        className={`w-full h-12 border-[1px] bg-white border-dark-gray outline-none focus:border-odf hover:border-odf rounded-r-lg px-4 py-2 `}
        value={_value?.phone || ""}
        onChange={_onChange}
      />
    </div>
  );
};

export default PhoneInput;
