import toast from "react-hot-toast";
import { ReactComponent as SuccessIcon } from "src/assets/svg/check.svg";
import { ReactComponent as WarningIcon } from "src/assets/svg/¡.svg";
import { ReactComponent as ErrorIcon } from "src/assets/svg/x2.svg";
import { ReactComponent as CloseIcon } from "src/assets/svg/x.svg";

const classes = {
  success: "bg-[#e0f1dc] border-[#6FCF97]",
  warning: "bg-[#fbefe7] border-[#FF914D]",
  error: "bg-[#f5d7d7] border-[#EB5757]"
};

const titles = {
  success: "Success",
  warning: "Something went wrong",
  error: "Failed"
};

const icons = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon
};

export interface IToast {
  message: string;
  type?: "success" | "error" | "warning";
}

const Toast = ({ message, type = "error" }: IToast) => {
  const Icon = icons[type];
  return (
    <div className={`relative flex py-3 px-5 rounded-lg border-[1px] ${classes[type]}`}>
      <Icon className="w-7 h-7 self-center" />
      <div className="ml-[18px] min-w-[90px]">
        <p className="text-[#333] font-semibold text-base font-poppins pr-4">{titles[type]}</p>
        <p className="text-sm text-[#4d4d4d] font-inter whitespace-pre-line ">{message}</p>
      </div>
      <CloseIcon
        className="absolute top-0 right-0 m-[10px] cursor-pointer"
        onClick={() => {
          toast.remove();
        }}
      />
    </div>
  );
};

export default Toast;
