import { useState } from "react";
import Checkbox from "./Checkbox";

type Value = { value: any; label: string };

export interface ICheckboxSelect {
  boxes: Value[];
  value?: Value; // If picked single it will have len 1 at most
  onChange?: (v?: Value) => void;
  checkboxClassName?: string;
}

const CheckboxSelect = ({ value, onChange, boxes, checkboxClassName = "" }: ICheckboxSelect) => {
  const [_value, setValue] = useState(value);

  const _onChange = (v: Value) => {
    let v2: Value | undefined = undefined;
    if (_value?.value !== v.value) v2 = v;
    setValue(v2);
    onChange?.(v2);
  };

  return (
    <div className="flex flex-wrap justify-between">
      {boxes.map((b) => (
        <Checkbox
          className={checkboxClassName}
          value={_value?.value === b.value}
          onChange={() => _onChange(b)}
          key={b.label}
          title={b.label}
          style={1}
        />
      ))}
    </div>
  );
};

export default CheckboxSelect;
