import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customToast } from "src/utils/toast";
import Client from "src/types/models/Client";
import { getClient as _get } from "src/network/client";

interface State {
  client?: Client;
}

const getInitialState = (): State => ({
  client: undefined
});

// ACTIONS

// This action will fetch the client data
export const getClient = createAsyncThunk("client/get", async () => {
  try {
    return await _get();
  } catch (e: any) {
    customToast({ message: "Authentication error: " + (e?.message || "Unknown") });
    return undefined;
  }
});

// This action will try to login the user when they are already loged with cognito
export const setClient = createAsyncThunk("client/set", async (data: Client) => {
  return data;
});

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (bl) => {
    bl.addCase(getClient.fulfilled, (state, action) => {
      state.client = action.payload;
    });
    bl.addCase(setClient.fulfilled, (state, action) => {
      state.client = action.payload;
    });
  }
});

export default authSlice.reducer;
