const dayInMs = 24 * 60 * 60 * 1000;
const hourInMs = 60 * 60 * 1000;
const minInMs = 60 * 100;

export const daysInMs = (days: number) => days * dayInMs;
export const hoursInMs = (hours: number) => hours * hourInMs;
export const minsInMs = (mins: number) => mins * minInMs;

// Utility for fake testing async
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
