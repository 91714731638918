import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "src/pages/auth/Login";
import LinkSentPage from "src/pages/auth/Login/LinkSentPage";
import MagicLinkPage from "src/pages/auth/Login/MagicLinkPage";
import SignUpPage from "src/pages/auth/Signup";
import ClientSignUpPage from "src/pages/auth/Signup/ClientSignUpPage";
import VerifyPage from "src/pages/auth/Signup/VerifyPage";
import { useAppSelector } from "src/redux/store";
import AuthenticatedRouter from "./AuthenticatedRouter";
import MemberSignUpPage from "src/pages/auth/Signup/MemberSignUpPage";
import { onMaintenance } from "src/config/env";
import MaintancePage from "src/pages/maintanance";

const AppRoutes = () => {
  const { user, invites } = useAppSelector((r) => r.auth);

  if (onMaintenance) return <MaintancePage />;

  if (user?.confirmed_sign_up) return <AuthenticatedRouter />;
  let routes = authRoutes;

  // If the user did not finish outdefine sign up force it
  if (user?.confirmed_sign_up === false) {
    // If they were invited by a company there is a different flow
    routes = invites?.length ? clientMemberSignUpRoutes : outdefineSignUpRoutes;
  }

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

const outdefineSignUpRoutes = [
  { path: "/signup/client", element: <ClientSignUpPage /> },
  { path: "/*", element: <Navigate to="/signup/client" replace /> }
];

const clientMemberSignUpRoutes = [
  { path: "/signup/client-member", element: <MemberSignUpPage /> },
  { path: "/*", element: <Navigate to="/signup/client-member" replace /> }
];

const authRoutes = [
  // Login
  { path: "/login", element: <LoginPage /> },
  { path: "/linksent", element: <LinkSentPage /> },
  { path: "/magic-link", element: <MagicLinkPage /> },
  // Sign up (COGNITO)
  { path: "/signup", element: <SignUpPage /> },
  { path: "/signup/confirm", element: <VerifyPage /> },
  // Default
  { path: "/*", element: <Navigate to="/login" replace /> }
];

export default AppRoutes;
