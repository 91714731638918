import FormController, { IFormController } from "../FormController";
import DropdownSelect from "./DropdownSelect";

interface IFormDropdownSelect extends IFormController {
  select: React.ComponentProps<typeof DropdownSelect>;
}

export const FormDropdownSelect = ({ select, ...props }: IFormDropdownSelect) => {
  return <FormController Children={DropdownSelect} childrenProps={select} {...props} />;
};
