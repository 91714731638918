export const isWorkEmail = (email: string) => {
  if (!email) {
    return false;
  }

  const domain = email.split("@")[1];

  const personalDomains = [
    "gmail.com",
    "outlook.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "protonmail.com",
    "icloud.com",
    "live.com",
    "zohomail.com",
    "mail.com"
  ];

  if (personalDomains.includes(domain)) {
    return false;
  }

  return true;
};

export const noSpaceAtStartNorEndRegex = RegExp(/^[^ ].*[^ ]$/);
export const urlRegex =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)$/i;
export const phoneRegex = RegExp(/^[0-9][0-9]{6,15}$/);
export const numericRegex = /^[0-9]+$/;
