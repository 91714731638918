import React, { ReactNode } from "react";
import { ReactComponent as Close } from "src/assets/svg/x3.svg";
import _Modal from ".";
import useClickOutside from "src/hooks/utils/useClickOutside";

export interface IModal {
  style?: 0;
  className?: string;
  onChange?: (b: boolean) => any;
  children?: ReactNode;
  open: boolean;
}

const Modal = ({ style = 0, className, open, onChange, children }: IModal) => {
  const Styled = Modals[style];
  const ref = React.useRef<any>(null);
  const _onChange = () => {
    onChange?.(!open);
  };
  useClickOutside(ref, _onChange);

  if (!open) return null;

  return (
    <_Modal>
      <div ref={ref}>
        <Styled className={className} onChange={_onChange}>
          {children}
        </Styled>
      </div>
    </_Modal>
  );
};

// Implement the styled modals u need
interface ISModal {
  className?: string;
  onChange?: () => void;
  children: ReactNode;
}

const Modal0 = ({ className = "", children, onChange }: ISModal) => (
  <div className={"bg-white rounded-[16px] p-6 w-fit h-fit  " + className}>
    <Close className="w-6 h-6 ml-auto cursor-pointer" onClick={onChange} />
    {children}
  </div>
);
const Modals = [Modal0];

export default Modal;
