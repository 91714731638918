import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type style = 0;

interface ITabs {
  className?: string;
  style?: style; // Different style formats but same logic
  tabs: string[];
  contents?: JSX.Element[]; // If you want to manage it yourself do not provide it
  tab?: string;
  withSearchParam?: boolean;
  onChange?: (s: string) => void;
}

const Tabs = ({
  className,
  style = 0,
  tabs,
  tab,
  onChange,
  contents = [],
  withSearchParam
}: ITabs) => {
  const [value, setValue] = useState(tab || tabs[0]);
  const [params, setSearchParam] = useSearchParams();
  const paramTab = params.get("tab");

  const { Wrapper, Tab } = _Tabs[style];
  const _onChange = (t: string) => {
    setValue(t);
    onChange?.(t);
    if (withSearchParam) setSearchParam(`tab=${t}`);
  };

  // In case they are refreshing
  useEffect(() => {
    if (!withSearchParam) return;
    if (paramTab && paramTab !== value) _onChange(paramTab);
  }, [paramTab]);

  return (
    <>
      <Wrapper className={className}>
        {tabs.map((t) => (
          <Tab tab={t} active={value === t} key={t} onSelect={() => _onChange(t)} />
        ))}
      </Wrapper>
      {contents[tabs.findIndex((t) => t === value) || 0]}
    </>
  );
};

interface IWrapper {
  className?: string;
  children?: any;
}

interface ITab {
  active: boolean;
  tab: string;
  onSelect: () => void;
}

const Wrapper1 = ({ children, className }: IWrapper) => (
  <div className={"flex flex-wrap gap-5 mb-6 " + className}>{children}</div>
);
const Tab1 = ({ active, tab, onSelect }: ITab) => (
  <button
    type="button"
    onClick={onSelect}
    className={`bg-odfblue px-4 py-2 rounded-[24px] text-white font-poppins font-semibold opacity-30 hover:opacity-100 ${active && "!opacity-100"} `}
  >
    {tab}
  </button>
);

const _Tabs = [{ Tab: Tab1, Wrapper: Wrapper1 }];

export default Tabs;
