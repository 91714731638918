import { useState } from "react";
import { customToast } from "src/utils/toast";

interface IOptions {
  successMsg?: string;
  errorMsg?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
}

/**
 * This Hook can be used for adding a loading state to networ calls
 */
function useLoader<A>(
  callback: A & ((args: any) => Promise<any>),
  options: IOptions = {}
): { loading: boolean; ldng: A } {
  const { successMsg, errorMsg, successCallback, errorCallback } = options;
  const [loading, setLoading] = useState(false);
  const ldng: any = async (args) => {
    setLoading(true);
    let result;
    try {
      result = await callback(args);
      if (successMsg) customToast({ message: successMsg, type: "success" });
      successCallback?.();
    } catch (e: any) {
      console.error(e);
      customToast({ message: errorMsg || e.message });
      errorCallback?.();
    }
    setLoading(false);
    return result;
  };

  return { loading, ldng };
}

export default useLoader;
