import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "src/assets/svg/<.svg";

export interface IContentLayout {
  title: ReactNode;
  children: ReactNode;
  rightChildren?: JSX.Element;
  className?: string; //For the content
  back?: string | (() => void); // A string if you want it to automatically navigate to that path
}

const ContentLayout = ({
  title,
  children,
  rightChildren,
  className = "",
  back
}: IContentLayout) => {
  const navigate = useNavigate();
  const onBack = typeof back === "function" ? back : () => navigate(back || "");

  return (
    <div className="w-full py-6 pr-6 h-screen  ">
      <div className="w-full rounded-[36px] bg-lighter-gray h-full overflow-y-hidden">
        <div className="flex px-9 py-[14px] border-b-[1px] border-dark-gray items-center min-h-[84px] ">
          {back && <BackArrow onClick={onBack} className="mr-4 cursor-pointer" />}
          <p className="font-poppins text-2xl font-semibold">{title}</p>
          <div className="ml-auto">{rightChildren}</div>
        </div>
        <div className={"p-9 h-[calc(100%-72px)] overflow-y-scroll " + className}>{children}</div>
      </div>
    </div>
  );
};

export default ContentLayout;
