import { phoneRegex } from "src/utils/constraints";
import FormController, { IFormController } from "../FormController";
import PhoneInput, { IPhone, IPhoneInput } from "./PhoneInput";

interface IFormPhoneInput extends IFormController {
  input?: IPhoneInput;
  required?: boolean;
}

const validate = {
  isRequired: (value: IPhone) => {
    if (!value?.dial) return "Dial code is required";
    if (!value?.phone) return "Phone is required";
    return true;
  },
  validPhone: (value: IPhone) =>
    !value?.phone || phoneRegex.test(value?.phone) || "Invalid phone format"
};

// This component will only accept validation in object form
export const FormPhoneInput = ({ input, rules, required, ...props }: IFormPhoneInput) => {
  if (!rules) rules = {};
  rules.validate = { ...rules?.validate, ...validate };
  if (!required) delete rules?.validate?.isRequired;
  return <FormController Children={PhoneInput} childrenProps={input} rules={rules} {...props} />;
};
