import { useSearchParams } from "react-router-dom";
import { JobClass } from "src/classes/models/job";
import Job from "src/types/models/Job";
import { FormTag } from "../../components/FormTag";
import { FormInput } from "src/components/Form/Input/FormInput";
import { FormRangeBoxes } from "src/components/Form/RangeBoxes/FormRangeBoxes";
import { FormCheckboxSelect } from "src/components/Form/Checkbox/FormCheckboxSelect";
import { FormSalaryRange } from "src/components/Form/SalaryRange/FormSalaryRange";
import { JOBTERM_OPTIONS, LOCATIONTYPE_OPTIONS, YES_NO_OPTIONS } from "src/constants/formOptions";
import { FormDropdownSelect } from "src/components/Form/DropdownSelect/FormDropdownSelect";
import { FormCityInput } from "src/components/Form/CityInput/FormCityInput";
import { FormTextArea } from "src/components/Form/TextArea/FormTextArea";
import { useForm } from "react-hook-form";
import { experienceOptions } from "src/components/Form/DropdownSelect/Options";
import { UpdateJobParams } from "src/network/job/type";

interface IJobDetaIl {
  job: Job;
  onUpdate: (params: UpdateJobParams) => void;
}
const JobDetail = ({ job, onUpdate }: IJobDetaIl) => {
  const [params] = useSearchParams();
  const editing = params.get("edit")?.includes("true");

  return (
    <div className="p-9 w-full rounded-2xl border-[1px] border-odfblue-hue bg-white">
      {editing ? <Edit onSubmit={onUpdate} job={job} /> : <Read job={job} />}
    </div>
  );
};

// --- READ VIEW ---
const Read = ({ job }: { job: Job }) => {
  const j = new JobClass(job);
  return (
    <>
      <div className="grid grid-cols-2 gap-10 max-w-[1100px]">
        <div>
          <Wrap title="Job Title" content={job.job_title} />
          <Wrap title="Years of Experience" content={j.yearsExperienceText()} />
          <Wrap title="Employment Type" content={j.locationTypeText()} />
          <Wrap title="Salary Range" content={j.salaryRangeText()} />
        </div>
        <div>
          <Wrap title="Experience Level" content={j.experienceLevelText()} />
          <Wrap title="Working Hours" content={job.term} />
          <Wrap title="Location(if onsite or hybrid)" content={job.city} />
          <Wrap title="Visa Sponsorship required?" content={j.visaText()} />
        </div>
      </div>
      <div className="flex flex-col gap-3 max-w-[1010px]">
        <Label>Job Description</Label>
        <Content>{job.description}</Content>
      </div>
    </>
  );
};

export const Wrap = ({ title, content }) => (
  <div className="flex flex-col gap-[25px] mb-10">
    <Label>{title}</Label>
    <Content>{content}</Content>
  </div>
);
const Label = ({ children }) => <p className="font-poppins font-semibold text-base">{children}</p>;
const Content = ({ children }) => (
  <p className="font-poppins text-sm whitespace-pre-line">{children}</p>
);

// --- EDIT VIEW ---
const Edit = ({ job, onSubmit }: { job: Job; onSubmit: (params: UpdateJobParams) => void }) => {
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({ defaultValues: jobToForm(job) });

  const base = { errors, control };

  const _onSubmit = (data: JobForm) => {
    const s = data.salary.salary;
    const _data: UpdateJobParams = {
      job_title: data.job_title,
      year_experience: data.year_experience,
      experience_level: data?.experience_level?.value,
      location_type: data?.location_type?.value,
      description: data.description,
      visa_sponsor: data?.visa_sponsor?.value,
      city: data.city,
      term: data?.term?.value,
      Salary: s[0]
        ? {
            currency: data.salary.currency,
            rate: s as any,
            period: data.salary.period
          }
        : undefined
    };
    onSubmit(_data);
  };

  return (
    <form id="update_job" onSubmit={handleSubmit(_onSubmit)}>
      <div className="flex gap-10 mb-12">
        {/* Left */}
        <div className="w-full">
          <FormTag>Job Title</FormTag>
          <FormInput {...base} rules={{ required: "Title is required" }} name="job_title" />

          <FormTag className="mt-12 !mb-3">Years of experience</FormTag>
          <FormRangeBoxes name="year_experience" {...base} rules={{ min: 1, max: 12 }} />

          <FormTag className="mt-12">Employment Type</FormTag>
          <FormCheckboxSelect
            {...base}
            name="location_type"
            rules={{ required: "Location type is required" }}
            select={{ boxes: LOCATIONTYPE_OPTIONS, checkboxClassName: "!w-[136px]" }}
          />

          <FormSalaryRange {...base} className="mt-12" name="salary" />
        </div>
        {/* Right */}
        <div className="w-full">
          <FormTag>Experience Level</FormTag>
          <FormDropdownSelect
            {...base}
            select={{
              _options: "experiences"
            }}
            rules={{ required: "Experience is required" }}
            className="mb-12"
            name="experience_level"
          />

          <FormTag>Working Hours</FormTag>
          <FormCheckboxSelect
            {...base}
            rules={{ required: "Working hours is required" }}
            name="term"
            select={{ boxes: JOBTERM_OPTIONS, checkboxClassName: "!w-[200px]" }}
          />

          <FormTag className="mt-12">Location</FormTag>
          <FormCityInput {...base} rules={{ required: "Location is required" }} name="city" />

          <FormTag className="mt-12">Visa Sponsorship required?</FormTag>
          <FormCheckboxSelect
            {...base}
            name="visa_sponsor"
            rules={{ required: "Required" }}
            select={{ boxes: YES_NO_OPTIONS, checkboxClassName: "!w-[200px]" }}
          />
        </div>
      </div>
      <FormTag>Job Description</FormTag>
      <FormTextArea
        errors={errors}
        area={{
          className: "p-4 h-[300px]"
        }}
        rules={{ required: "Description is required" }}
        name="description"
        control={control}
      />
    </form>
  );
};

const jobToForm = (j: Job) => ({
  description: j.description,
  job_title: j.job_title,
  term: JOBTERM_OPTIONS.find((o) => o.value === j.term),
  year_experience: [j.year_experience[0].value, j.year_experience[1].value],
  location_type: LOCATIONTYPE_OPTIONS.find((o) => o.value === j.location_type),
  city: j.city,
  experience_level: experienceOptions.find((o) => o.value === j.experience_level),
  visa_sponsor: YES_NO_OPTIONS.find((o) => o.value === j.visa_sponsor),
  salary: {
    period: j.Salary?.period || "Hourly",
    currency: j.Salary?.currency || "USD",
    salary: [j.Salary?.rate[0].value, j.Salary?.rate[1].value]
  }
});

type JobForm = ReturnType<typeof jobToForm>;

export default JobDetail;
