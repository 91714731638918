import { IInput } from "../Input/Input";
import usePlacesAutocomplete from "use-places-autocomplete";
import { getCountryCode } from "countries-list";
import React, { useEffect, useState } from "react";
import useClickOutside from "src/hooks/utils/useClickOutside";

export interface ICityInput extends IInput {
  country?: string; // Restriction for the cities to be of this country
  onChange?: (string) => void;
}

const CityInput = ({ onChange, className, country, value, ...props }: ICityInput) => {
  // Hooks
  const [_value, _setValue] = useState(value || "");
  const {
    suggestions: { data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      types: ["(cities)"],
      componentRestrictions: { country: country && getCountryCode(country) }
    },
    debounce: 500
  });
  // Close suggestions if they click outside
  const suggestionRef = React.useRef<HTMLOListElement>(null);
  useClickOutside(suggestionRef, clearSuggestions);

  // Handlers
  const _onChange = (city) => {
    city = city.target.value;
    setValue(city);
    _setValue(city);
    onChange?.(city);
  };
  const onSelect = (suggestion) => {
    onChange?.(suggestion);
    _setValue(suggestion);
    clearSuggestions();
  };

  // Allow to use a default value, since `value` is initialized as an empty string
  useEffect(() => {
    if (value) onChange?.({ target: { value } } as any);
  }, []);

  return (
    <div className={"relative w-auto font-poppins text-sm  "}>
      {/* Input */}
      <input
        className={`w-full h-12 border-[1px] bg-white border-dark-gray outline-none focus:border-odf hover:border-odf rounded-lg px-4 py-2 
      ${className}`}
        value={_value}
        onChange={_onChange}
        {...props}
      />

      {/* Suggestions list */}
      {!!data?.length && (
        <ol
          ref={suggestionRef}
          className="w-full absolute z-20 bg-white rounded-2xl py-4 border-[1px] border-light-gray "
        >
          {data.map(({ description }) => (
            <li
              key={description}
              onClick={() => onSelect(description)}
              className=" hover:bg-odf-hue3 px-4 py-2 cursor-pointer  "
            >
              {description}
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};

export default CityInput;
