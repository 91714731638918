import Logo from "src/components/Logo/Logo";
import Maint from "src/assets/images/generic/maintanance.png";

const MaintancePage = () => {
  return (
    <div className="w-fit mx-auto px-[36px] pt-5 flex flex-col items-center  text-center">
      <Logo className="w-[195px] p-4" />

      <p className="font-poppints text-[36px] font-bold mt-[80px]">We're undergoing maintenance.</p>

      <img src={Maint} className="w-[450px] h-[200px] my-[60px]" />

      <p className="font-poppints text-2xl font-bold">Please come back soon!</p>
      <p className="font-poppins text-xl text-[#8A8A8A] max-w-[750px] mt-4">
        We're undergoing scheduled maintenance to enhance our system, which may cause brief service
        interruptions. We apologize for any inconvenience.
      </p>
    </div>
  );
};

export default MaintancePage;
