import { ResourcesConfig } from "aws-amplify";
import { isProduction } from "../env";

const awsConfig: ResourcesConfig = isProduction
  ? {
      Auth: {
        Cognito: {
          // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
          identityPoolId: "us-east-1:944728e5-17e3-4175-aebc-53f48aa807b7",
          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: "us-east-1_XVW88aQO4",
          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolClientId: "7lbet9sr9bs3tc5pdoe427m155",
          // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
          loginWith: {
            oauth: {
              domain: "outdefine.auth.us-east-1.amazoncognito.com",
              scopes: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
              redirectSignIn: [`${window.location.origin}`],
              redirectSignOut: [`${window.location.origin}/login`],
              responseType: "token" // note that REFRESH token will only be generated when the responseType is code
            }
          }
        }
      },
      API: {
        REST: {
          "prod-outdefine-app-backend": {
            endpoint: "https://j0kzom1x6e.execute-api.us-east-1.amazonaws.com/prod/outdefine"
          }
        }
      },
      Storage: {
        S3: {
          bucket: "prod-outdefine-resume", //REQUIRED -  Amazon S3 bucket
          region: "us-east-1"
        }
      }
    }
  : {
      Auth: {
        Cognito: {
          identityPoolId: "us-west-1:12da32e4-d78e-4f67-8cc4-ac38adfb3952",
          userPoolId: "us-west-1_IQ8qffeE9",
          userPoolClientId: "5lps193lvr6pdkhjaivart3l7t",
          loginWith: {
            oauth: {
              domain: "outdefine.auth.us-west-1.amazoncognito.com",
              scopes: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
              redirectSignIn: [`${window.location.origin}`],
              redirectSignOut: [`${window.location.origin}/login`],
              responseType: "token"
            }
          }
        }
      },
      API: {
        REST: {
          "dev-outdefine-app-backend": {
            endpoint: "https://by9l3ro807.execute-api.us-west-1.amazonaws.com/dev/outdefine"
          }
        }
      },
      Storage: {
        S3: {
          bucket: "outdefine-applicant-resume-bucket",
          region: "us-west-1"
        }
      }
    };

export default awsConfig;
