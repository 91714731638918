import { ClientInvite } from "src/types/models/ClientInvite";
import { get, post, del } from "../utils";
import { InviteParams } from "./type";

export async function sendClientInvite(body: InviteParams): Promise<ClientInvite> {
  return (await post({ path: "outdefine_hire/client_invite", body })).body?.invite as ClientInvite;
}

// Get the invitations of the user being invited
export async function getMyInvites(): Promise<ClientInvite[]> {
  return (await get({ path: "outdefine_hire/client_invite/myinvites" })).body?.invites;
}

export async function getInvites(): Promise<ClientInvite[]> {
  return (await get({ path: "outdefine_hire/client_invite" })).body?.data;
}

export async function deleteInvite(id: number) {
  return (await del({ path: "outdefine_hire/client_invite", suffix: id.toString() })).body?.data;
}
