import { useEffect, useState } from "react";

export interface ITextArea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value?: string;
}

const TextArea = ({ value, onChange, className, ...props }: ITextArea) => {
  const [_value, setValue] = useState(value || "");

  const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  // Allow to use a default value, since `value` is initialized as an empty string
  useEffect(() => {
    if (value) onChange?.({ target: { value } } as any);
  }, []);

  return (
    <div className="relative w-auto font-poppins text-sm">
      <textarea
        className={`w-full h-12 border-[1px] bg-white border-dark-gray outline-none focus:border-odf hover:border-odf rounded-lg p-4 
      ${className}`}
        value={_value}
        onChange={_onChange}
        {...props}
      />
    </div>
  );
};

export default TextArea;
