import { IParamSkill } from "src/network/job/type";
import { IStep } from ".";
import { FormTag } from "../components/FormTag";
import { ReactComponent as CheckIcon } from "src/assets/svg/check2.svg";
import Button from "src/components/Button";
import { ReactComponent as Plus } from "src/assets/svg/+2.svg";
import Modal, { IModal } from "src/components/Modal/Modal";
import { useForm } from "react-hook-form";
import { FormInput } from "src/components/Form/Input/FormInput";
import { FormCheckboxSelect } from "src/components/Form/Checkbox/FormCheckboxSelect";
import { SKILL_TYPE_OPTIONS } from "src/constants/formOptions";
import { useState } from "react";
import { JobSkillType } from "src/types";
import FormErrorSpan from "src/components/Form/FormErrorSpan";

const Step2 = ({ form }: IStep) => {
  const { watch, setValue } = form;
  const [modal, setModal] = useState(false);
  const iSkills = watch("intercultural_skills");
  const tSkills = watch("technical_skills");

  const markedSkill = (ss: IParamSkill[], name: string) =>
    ss.map((s) => (s.name === name ? { ...s, for_interview: !s.for_interview } : s));

  const addSkill = (s: AddSkill) => {
    const inter = s.type === "Intercultural";
    const skills = inter ? iSkills : tSkills;
    setValue(inter ? "intercultural_skills" : "technical_skills", [
      ...skills.filter((sk) => sk.name !== s.name),
      { name: s.name, for_interview: false }
    ]);
  };

  return (
    <>
      <div className="flex">
        <div>
          <p className="font-poppins text-[20px] font-semibold ">
            Highlight the essential skills for the position.
          </p>
          <p className="font-poppins text-sm text-inactive-gray mt-4 ">
            Here are the skills we got from the JD. highlight the top skills you want Debbie to
            interview candidates on.
          </p>
        </div>
        <Button
          onClick={() => setModal(true)}
          shapeVariant={2}
          variant="white_blue"
          className="ml-auto !p-4"
        >
          <Plus className="mr-[10px] h-6 w-6" /> Add a new skill
        </Button>
      </div>

      <FormTag className="mt-12">Technical Skills</FormTag>
      <Skills
        skills={tSkills}
        onMark={(s) => setValue("technical_skills", markedSkill(tSkills, s))}
      />

      <FormTag className="mt-12">Intercultural Skills</FormTag>
      <Skills
        skills={iSkills}
        onMark={(s) => setValue("intercultural_skills", markedSkill(iSkills, s))}
      />

      {modal && <AddSkillModal onAdd={addSkill} onChange={setModal} />}
    </>
  );
};

const Skills = ({ skills, onMark }: { skills: IParamSkill[]; onMark: (s: string) => void }) => (
  <div className="flex gap-3 flex-wrap mt-6">
    {skills.map((s) => (
      <button
        type="button"
        onClick={() => onMark(s.name)}
        key={s.name}
        className={`p-4 bg-lighter-gray flex flex-wrap gap-2 rounded-lg font-poppins text-sm  items-center 
           ${s.for_interview ? "text-odfblue border-[1px]  border-odfblue" : "text-black"} `}
      >
        {s.name}
        {s.for_interview && <CheckIcon className="w-4 h-4 fill-odfblue " />}
      </button>
    ))}
  </div>
);

const defaultValues = {
  name: "",
  type: SKILL_TYPE_OPTIONS[0]
};

type AddSkill = { type: JobSkillType; name: string };

const AddSkillModal = ({
  onAdd,
  onChange
}: { onAdd: (skill: AddSkill) => void } & Pick<IModal, "onChange">) => {
  const {
    control,
    formState: { errors },
    watch
  } = useForm({ defaultValues });
  const type = watch("type")?.value;
  const name = watch("name");
  const _onAdd = () => {
    onAdd({ type, name });
    onChange?.(false);
  };
  const base = { errors, control };

  return (
    <Modal className="!w-[500px]" open={true} onChange={onChange}>
      <p className="font-poppins text-xl text-center font-semibold py-6">Add a new skill</p>

      <FormTag className=" font-[500]">Enter Skill</FormTag>
      <FormInput
        {...base}
        rules={{ required: "Title is required" }}
        input={{ placeholder: "Enter skill name" }}
        name="name"
      />
      {!name && <FormErrorSpan text="Required" />}

      <FormTag className=" font-[500] mt-6">Select type of skill</FormTag>
      <FormCheckboxSelect
        {...base}
        rules={{ required: "Type is required" }}
        name="type"
        className="w-[300px]"
        select={{ boxes: SKILL_TYPE_OPTIONS, checkboxClassName: " !gap-3" }}
      />
      {!type && <FormErrorSpan text="Required" />}

      <Button onClick={_onAdd} disabled={!type || !name} className="mt-6 mx-auto" shapeVariant={2}>
        Add new skill
      </Button>
    </Modal>
  );
};

export default Step2;
