import FormController, { IFormController } from "../FormController";
import CityInput, { ICityInput } from "./CityInput";

interface IFormCityInput extends IFormController {
  cityinput?: ICityInput;
}

export const FormCityInput = ({ cityinput, ...props }: IFormCityInput) => {
  return <FormController Children={CityInput} childrenProps={cityinput} {...props} />;
};
