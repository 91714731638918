import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PATHS_MAP } from "src/constants/paths";
import { useEffect } from "react";
import { useAppDispatch } from "src/redux/store";
import { getClient } from "src/redux/slices/client";
import JobsPage from "src/pages/job";
import CreateJobPage from "src/pages/job/create";
import JobDetailPage from "src/pages/job/detail";
import JobInvitePage from "src/pages/job/invite";
import InterviewDetailPage from "src/pages/interview/detail";
import SettingsPage from "src/pages/settings";

const defaultPath = "/" + PATHS_MAP.job.path;

// Dedicated router for signed up users
const AuthenticatedRouter = () => {
  const dispatch = useAppDispatch();

  // Fetch the client profile once authenticated
  useEffect(() => {
    dispatch(getClient());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

const routes = [
  // Jobs
  {
    path: defaultPath,
    element: <JobsPage />
  },
  {
    path: PATHS_MAP.job.subs?.create,
    element: <CreateJobPage />
  },
  { path: PATHS_MAP.job.subs?.detail, element: <JobDetailPage /> },
  {
    path: PATHS_MAP.job.subs?.invite,
    element: <JobInvitePage />
  },
  { path: PATHS_MAP.job.subs?.interview_detail, element: <InterviewDetailPage /> },
  // Settings
  { path: PATHS_MAP.settings.path, element: <SettingsPage /> },

  { path: "/*", element: <Navigate to={defaultPath} replace /> }
];

export default AuthenticatedRouter;
