import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "src/components/Button";
import { FormEmailInput } from "src/components/Form/EmailInput/FormEmailInput";
import { FormInput } from "src/components/Form/Input/FormInput";
import Modal from "src/components/Modal/Modal";
import { customToast } from "src/utils/toast";
import { ReactComponent as Close } from "src/assets/svg/x4.svg";
import { ReactComponent as Plus } from "src/assets/svg/+2.svg";

const defaultValues = {
  email: "",
  first_name: "",
  last_name: ""
};

export type Invite = typeof defaultValues;

const Step1 = ({ onInvites }: { onInvites: (_: Invite[]) => void }) => {
  const [invites, setInvites] = useState<Invite[]>([]);
  const [open, setOpen] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ defaultValues });
  const base = { errors, control, input: { required: true } };

  const onSubmit = (data: Invite) => {
    const exists = invites.find((i) => i.email === data.email);
    if (exists) {
      customToast({ message: "Alreaady on the list" });
      return;
    }
    setInvites([...invites, data]);
    setOpen(false);
    reset();
  };

  const remove = (index) => {
    invites.splice(index, 1);
    setInvites([...invites]);
  };

  useEffect(() => {
    onInvites(invites);
  }, [invites]);

  return (
    <>
      <p className="font-poppinst text-base font-semibold mb-9">
        Review all the emails you want to invite for the interview
      </p>

      <div className="grid grid-cols-2 gap-y-[35px] gap-x-[64px] mb-9 max-w-[1400px] w-full">
        {invites.map((i, index) => (
          <InviteBox key={i.email} invite={i} onRemove={() => remove(index)} />
        ))}
      </div>

      <Button
        onClick={() => setOpen(true)}
        className="!w-fit !h-fit !px-6 !py-4 !rounded-[24px] "
        variant="light_blue"
      >
        <Plus className="mr-[10px] " />
        Add New Talent
      </Button>

      <Modal open={open} onChange={setOpen}>
        <form onSubmit={handleSubmit(onSubmit)} className="w-[452px]">
          <p className="font-poppins text-center text-[20px] font-semibold mt-6">
            Add new talent to Invite
          </p>

          <p className="font-poppins text-sm mt-6 mb-3 font-[500]">First Name</p>
          <FormInput name="first_name" {...base} />

          <p className="font-poppins text-sm mt-6 mb-3 font-[500]">Last Name</p>
          <FormInput name="last_name" {...base} />

          <p className="font-poppins text-sm mt-6 mb-3 font-[500]">Email</p>
          <FormEmailInput name="email" {...base} />

          <Button type="submit" className="!rounded-[24px] !h-fit mx-auto mt-6 !py-4 !w-fit">
            Add talent
          </Button>
        </form>
      </Modal>
    </>
  );
};

const InviteBox = ({ invite, onRemove }: { invite: Invite; onRemove: () => void }) => {
  const { first_name, last_name, email } = invite;
  return (
    <div className="rounded-lg p-[10px] flex gap-[10px] items-center w-fit max-w-full border-[1px] border-light-gray">
      <p className="w-[42px] h-[42px] bg-[#D9D9FF] p-2 rounded-full font-poppins font-semibold text-base text-center text-odfblue shrink-0">
        {first_name[0].toUpperCase()}
      </p>
      <p className="p-[10px] bg-[#F9F9F9] rouned-lg font-poppins font-[500] text-sm whitespace-nowrap overflow-hidden text-ellipsis">
        {first_name + " " + last_name}
      </p>
      <p className="p-[10px] bg-[#F9F9F9] rouned-lg font-poppins font-[500] text-sm whitespace-nowrap overflow-hidden text-ellipsis">
        {email}
      </p>
      <div className="bg-[#CCC] w-[0.5px] h-[42px]" />
      <Close className="w-6 h-6 shrink-0 cursor-pointer" onClick={onRemove} />
    </div>
  );
};

export default Step1;
