import useLoader from "src/hooks/useLoader";
import useSlugParam from "src/hooks/useSlug";
import useSoftSearchParam from "src/hooks/utils/useSoftSearchParam";
import { updateJob } from "src/network/job";
import { UpdateJobParams } from "src/network/job/type";
import Job from "src/types/models/Job";

// The purpose of this hook is to store the state of updating, while providing wrappers
// that gives the basic features for updating
const useUpdate = (setJob: (j: Job) => void) => {
  const { softSetSearchParam } = useSoftSearchParam();
  const { id } = useSlugParam();
  // Edit template
  const onEdit = async (params: UpdateJobParams) => {
    softSetSearchParam("edit", "_true");
    const job = await updateJob({ id: Number(id), ...params });
    setJob(job);
  };
  const { ldng: update, loading: updating } = useLoader(onEdit, {
    errorMsg: "Error occured on edition",
    successMsg: "Job updated",
    successCallback: () => softSetSearchParam("edit", false),
    errorCallback: () => softSetSearchParam("edit", true)
  });
  return { updating, update };
};

export default useUpdate;
