import FormController, { IFormController } from "../FormController";
import TextArea, { ITextArea } from "./TextArea";

interface IFormTextArea extends IFormController {
  area?: ITextArea;
}

export const FormTextArea = ({ area, ...props }: IFormTextArea) => {
  return <FormController Children={TextArea} childrenProps={area} {...props} />;
};
