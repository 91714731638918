import { useState } from "react";
import { ReactComponent as Arrow } from "src/assets/svg/V.svg";
import { Transcript } from "src/types";

const roles = {
  assistant: "Debbie",
  user: "Candidate"
};

const Conversation = ({ transcript }: { transcript: Transcript }) => {
  const [open, setOpen] = useState(true);
  return (
    <div className="w-full flex flex-col gap-6">
      <Wrap>
        <button
          type="button"
          onClick={() => setOpen(!open)}
          className="w-full flex justify-between font-semibold"
        >
          Interview Transcripts
          <Arrow className={"w-6 h-6 " + (open && "rotate-180")} />
        </button>
      </Wrap>
      {open && (
        <Wrap className="flex flex-col gap-4">
          {transcript.map((q, i) => (
            <p key={i} className="font-poppins text-base whitespace-pre-line">
              <span className="font-semibold">{roles[q.role]}:</span>{" "}
              {q.content.map((c) => c.text).join("\n")}
            </p>
          ))}
        </Wrap>
      )}
    </div>
  );
};

const Wrap = ({ children, className = "" }) => (
  <div className={"bg-white rounded-lg p-4 font-poppins text-base " + className}>{children}</div>
);

export default Conversation;
