import OutdefineLocalStorage from "src/types/storage";
import { daysInMs, hoursInMs, minsInMs } from "./time";

// ***LOCAL STORAGE***
const APP_ID = "OUTDEFINE_";
type Key = keyof OutdefineLocalStorage;

export function setItem<K extends Key>(key: K, data: OutdefineLocalStorage[K]) {
  localStorage.setItem(APP_ID + key, data);
}

export function getItem<K extends Key>(key: K): OutdefineLocalStorage[K] | undefined {
  try {
    return localStorage.getItem(APP_ID + key) as OutdefineLocalStorage[K];
  } catch (e) {
    // Sentry.captureException(e);
    return undefined;
  }
}

// Remove all not outdefine localStorage
export const selectiveClear = () => {
  Object.keys(localStorage).forEach((key) => {
    if (!key.startsWith(APP_ID)) delete localStorage[key];
  });
  sessionStorage.clear();
};

// ***COOKIES***
type cookie = "redirect_url";
export const setCookie = (name: cookie, value: string, { days = 0, hours = 0, mins = 0 }) => {
  const d = new Date();
  d.setTime(d.getTime() + daysInMs(days) + hoursInMs(hours) + minsInMs(mins));
  const expires = "expires=" + d.toUTCString();
  document.cookie = APP_ID + name + "=" + value + ";" + expires + ";path=/";
};

export function getCookie(name: cookie) {
  const regex = new RegExp(`(^| )${APP_ID + name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) return match[2];
}

export function deleteCookie(name: cookie) {
  setCookie(name, "", { mins: 1 });
}
