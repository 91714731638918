import { useSearchParams } from "react-router-dom";
import { JobClass } from "src/classes/models/job";
import Job from "src/types/models/Job";
import { Wrap } from "./JobDetail";
import { useForm } from "react-hook-form";
import { UpdateJobParams } from "src/network/job/type";
import { FormTag } from "../../components/FormTag";
import { FormCheckboxSelect } from "src/components/Form/Checkbox/FormCheckboxSelect";
import { INTERVIEW_DURATION_OPTIONS } from "src/constants/formOptions";

interface ISettings {
  job: Job;
  onUpdate: (params: UpdateJobParams) => void;
}
const Settings = ({ job, onUpdate }: ISettings) => {
  const [params] = useSearchParams();
  const editing = params.get("edit")?.includes("true");

  return (
    <div className="p-9 w-full rounded-2xl border-[1px] border-odfblue-hue bg-white">
      {editing ? <Edit onSubmit={onUpdate} job={job} /> : <Read job={job} />}
    </div>
  );
};

// --- READ ----
const Read = ({ job }: { job: Job }) => {
  const j = new JobClass(job);
  return (
    <>
      <div className="grid grid-cols-2 gap-10 max-w-[1100px]">
        <div>
          <Wrap title="Interview Duration" content={j.interviewDurationText()} />
        </div>
        <div></div>
      </div>
    </>
  );
};

// --- EDIT ----
const Edit = ({ job, onSubmit }: { job: Job; onSubmit: (params: UpdateJobParams) => void }) => {
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({ defaultValues: jobToForm(job) });

  const base = { errors, control };

  const _onSubmit = (data: JobForm) => {
    const _data: UpdateJobParams = {
      interview_duration: data.interview_duration?.value
    };
    onSubmit(_data);
  };

  return (
    <form id="update_job" onSubmit={handleSubmit(_onSubmit)}>
      <FormTag>Interview Duration</FormTag>
      <FormCheckboxSelect
        {...base}
        name="interview_duration"
        rules={{ required: "Duration is required" }}
        select={{
          boxes: INTERVIEW_DURATION_OPTIONS,
          checkboxClassName: "!w-[136px]"
        }}
        className="max-w-[500px]"
      />
    </form>
  );
};

const jobToForm = (j: Job) => ({
  interview_duration: INTERVIEW_DURATION_OPTIONS.find((o) => o.value === j.interview_duration)
});

type JobForm = ReturnType<typeof jobToForm>;

export default Settings;
