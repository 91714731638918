import { useState } from "react";
import Input, { IInput } from "../Input/Input";

const EmailInput = (props: IInput) => {
  const [value, setValue] = useState<string>("");
  const onChange = (e) => {
    const value = e.target.value;
    // The raw value should always be lowercase in emails
    if (props.onChange) props.onChange(value.toLowerCase());
    setValue(value);
  };

  return <Input type="email" placeholder="Email" {...props} onChange={onChange} value={value} />;
};

export default EmailInput;
