interface IFormErrorSpan {
  text: string;
}

const FormErrorSpan = ({ text }: IFormErrorSpan) => {
  if (!text) return null;
  return <span className="font-poppins text-xs text-red mt-2">{text}</span>;
};

export default FormErrorSpan;
