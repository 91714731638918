import Spinner from "src/components/Spinner";

export const CountCard = ({ count, title }: { count?: number; title: string }) => (
  <div className="w-[337px] p-6 flex justify-between items-center rounded-2xl border-[1px] border-[rgba(95,95,255,0.20)]">
    <p className="font-poppins text-base font-semibold">{title}</p>
    <p className="px-4 py-1 bg-[rgba(20,20,20,0.05)] font-poppins text-[32px] font-semibold rounded-lg">
      {count === undefined ? <Spinner /> : count}
    </p>
  </div>
);
