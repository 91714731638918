import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";

import AppRoutes from "./router";
import awsConfig from "./config/aws/config";
import { useAppDispatch } from "./redux/store";
import { login } from "./redux/slices/auth";
import LoaderPage from "./components/Loader/LoaderPage";

// Base configuration for amplify
Amplify.configure(awsConfig);

const App: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Try to logging the user in case he is coming back and session did not expire yet
    (async () => {
      setLoading(true);
      await dispatch(login());
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    // Customer support
    (window as any).plugSDK.init({
      app_id:
        "DvRvStPZG9uOmNvcmU6ZHZydi11cy0xOmRldm8vMWpGWHYwRmtLSzpwbHVnX3NldHRpbmcvMV9ffHxfXzIwMjQtMTEtMjkgMjE6Mjc6MzUuMTAwOTA0Mzk4ICswMDAwIFVUQw==xlxendsDvRv"
    });
  }, []);

  if (loading) return <LoaderPage className="h-screen" />;

  return (
    <div>
      <AppRoutes />
    </div>
  );
};

export default App;
