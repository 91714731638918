import { useSearchParams } from "react-router-dom";
import Button from "src/components/Button";
import { ReactComponent as EditIcon } from "src/assets/svg/edit.svg";
import { ReactComponent as CheckIcon } from "src/assets/svg/check3.svg";
import useSoftSearchParam from "src/hooks/utils/useSoftSearchParam";

const EditButton = ({ form }: { form?: string }) => {
  const [params] = useSearchParams();
  const { softSetSearchParam } = useSoftSearchParam();
  const par = params.get("edit") || "false";
  // _true is for loading
  const editing = par === "true" || par === "_true";
  const submiting = par === "_true";

  return editing ? (
    <Button loading={submiting} type="submit" form={form} shapeVariant={2} variant="primary">
      <CheckIcon className="mr-2" />
      Save Changes
    </Button>
  ) : (
    <Button
      onClick={(e) => {
        e.preventDefault();
        softSetSearchParam("edit", true);
      }}
      shapeVariant={2}
      variant="transparent_blue"
    >
      <EditIcon className="mr-2 group-hover:stroke-white" />
      Edit
    </Button>
  );
};

export default EditButton;
