import FormController, { IFormController } from "../FormController";
import Checkbox, { ICheckbox } from "./Checkbox";

interface IFormCheckbox extends IFormController {
  box?: ICheckbox;
}

export const FormCheckbox = ({ box, ...props }: IFormCheckbox) => {
  return <FormController Children={Checkbox} childrenProps={box} {...props} />;
};
