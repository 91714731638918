import { AuthUser, getCurrentUser, signOut } from "aws-amplify/auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUser } from "src/network/user";
import User from "src/types/models/User";
import { customToast } from "src/utils/toast";
import { getMyInvites } from "src/network/client_invite";
import { ClientInvite } from "src/types/models/ClientInvite";

interface State {
  user?: User;
  invites?: ClientInvite[];
}

const getInitialState = (): State => ({
  user: undefined,
  invites: undefined
});

// ACTIONS

// This action will try to login the user when they are already loged with cognito
export const login = createAsyncThunk("auth/login", async () => {
  try {
    let _user: AuthUser;
    try {
      _user = await getCurrentUser();
    } catch (e) {
      // He is not authenticated so do nothing
      return getInitialState();
    }
    const email = _user.signInDetails?.loginId;
    if (!email) throw new Error("Not authenticated via cognito");
    const user = await getUser(email);

    // Only clients allowed on this app
    if (user.confirmed_sign_up && !user.is_client) {
      throw new Error("You are not a client user");
    }

    // If the user is not confirmed we need to fetch his invitation so he can join a company
    let invites: ClientInvite[] | undefined = undefined;
    if (!user.confirmed_sign_up) invites = await getMyInvites();

    return { user, invites };
  } catch (e: any) {
    await logoutRoutine();
    customToast({ message: "Authentication error: " + (e?.message || "Unknown") });
    return getInitialState();
  }
});

async function logoutRoutine() {
  await signOut();
}

export const logout = createAsyncThunk("auth/logut", async () => {
  await logoutRoutine();
});

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (bl) => {
    bl.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.invites = action.payload.invites;
    });
    bl.addCase(logout.fulfilled, () => {
      return getInitialState();
    });
  }
});

export default authSlice.reducer;
