import Button from "src/components/Button";

const Page500 = ({ error, resetErrorBoundary }) => {
  //   useEffect(() => {
  //     mixpanel_people_increment({ number_of_crash: 1 });
  //     mixpanel_track("Website crashed");
  //   }, []);

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center bg-white">
      <div className="h-[30px] px-[10px]">Thats an error</div>
      <h1 className="text-[64px] font-poppins font-bold text-odf mt-2">Oh No! Error 500</h1>
      <p className="text-odf text-xs font-inter leading-6 max-w-[590px] text-center">
        The server encountered an error and can not complete your request. If the error continues
        please contact our support team{" "}
        <a
          href="mailto:support@outdefine.com"
          className="font-inter font-bold decoration-theme underline"
        >
          support@outdefine.com
        </a>
      </p>
      <a href="/">
        <Button className="mt-10 text-sm ">Return to homepage</Button>
      </a>
    </div>
  );
};

export default Page500;
