import Chart from "react-apexcharts";
import { FeedbackData } from "src/classes/models/interview";

interface IFeedbackCard {
  feedback: FeedbackData;
}

const FeedbackCard = ({ feedback }: IFeedbackCard) => {
  const { score, _score } = feedback;
  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="font-poppins text-2xl font-bold text-odfblue">{feedback.title}</p>
        <div className=" w-[100px] h-[100px] overflow-visible">
          <Chart
            type="radialBar"
            width="100%"
            height="100%"
            series={[_score.number]}
            options={{
              labels: [score],
              chart: { type: "radialBar" },
              fill: { colors: [_score.style.color] },
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      show: true,
                      fontFamily: "Poppins",
                      color: "black",
                      fontSize: "24px",
                      fontWeight: 600,
                      offsetY: 7
                    },
                    value: {
                      show: false
                    }
                  },
                  hollow: {
                    size: "50%"
                  }
                }
              }
            }}
          />
        </div>
      </div>

      <div className="w-full rounded-lg bg-white p-4 font-poppins text-sm mt-3 whitespace-pre-line">
        {feedback.summary}
      </div>
    </div>
  );
};

export default FeedbackCard;
