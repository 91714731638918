import {
  COUNTRIES,
  CURRENCIES,
  EXPERIENCE_LEVELS,
  NUMBER_OF_EMPLOYEES,
  PERIODS
} from "src/constants";
import { dialCodes } from "src/constants/phone";

export const generateOptions = (options: string[] | number[]) =>
  options.map((o) => ({ value: o, label: o.toString() }));

export const nmbOfEmplOptions = generateOptions(NUMBER_OF_EMPLOYEES);
export const countriesOptions = generateOptions(COUNTRIES);
export const usaCountryOption = countriesOptions.find((o) => o.value === "United States");
export const dialCodeOptions = dialCodes.map((d) => ({ label: d.value, value: d.code }));
export const usDialCodeOption = dialCodeOptions.find((d) => d.value === "+1");
export const experienceOptions = generateOptions(EXPERIENCE_LEVELS);
export const periodOptions = generateOptions(PERIODS);
export const currencyOptions = generateOptions(CURRENCIES);

export type Option = (typeof dialCodeOptions)[0];

export function getOptionsMap(options: typeof nmbOfEmplOptions) {
  const result: { [key in string]: Option } = {};
  options.forEach((o) => (result[o.value] = o));
  return result;
}

export const currencyOptionsMap = getOptionsMap(currencyOptions);
export const periodOptionsMap = getOptionsMap(periodOptions);
