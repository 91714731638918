import { useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "src/assets/svg/+.svg";
import Button from "src/components/Button";
import Table from "src/components/Tables/Table";
import usePagination from "src/hooks/usePagination";
import { getJobsPaginated } from "src/network/job";
import { useAppSelector } from "src/redux/store";
import Layout from "src/router/AuthenticatedRouter/Layout";
import Job from "src/types/models/Job";
import Empty from "./components/Empty";
import PaginatedDiv from "src/components/PaginatedDiv";
import { CountCard } from "./components/CountCard";
import { useEffect, useState } from "react";
import { InterviewMetrics } from "src/network/interview/type";
import { getInterviewMetrics } from "src/network/interview";
import { JobClass } from "src/classes/models/job";
import InitialsLogo from "src/components/InitialsLogo";

const JobsPage = () => {
  const user = useAppSelector((r) => r.client?.client?.User);
  const navigate = useNavigate();
  const { data, count, fetching, fetch } = usePagination<Job>({
    fetchPage: getJobsPaginated,
    page_size: 10
  });
  const [metrics, setMetrics] = useState<InterviewMetrics>();

  // Fetch metrics
  useEffect(() => {
    (async () => {
      const m = await getInterviewMetrics();
      setMetrics(m);
    })();
  }, []);

  return (
    <Layout
      title={`Hello, ${user?.full_name || ""}! 👋`}
      className="!overflow-hidden flex flex-col"
      rightChildren={
        <Button
          onClick={() => navigate("/job/create")}
          className="!rounded-3xl !py-4 !px-6 !h-fit"
          variant="white_blue"
        >
          <Plus className="mr-[10px] h-6 w-6" /> Post a Job
        </Button>
      }
    >
      <div className="flex gap-12 mb-20">
        <CountCard title="Jobs Posted" count={count} />
        <CountCard title="Invited Talent" count={metrics?.TOTAL} />
        <CountCard title="Interviewed Talent" count={metrics?.INTERVIEWED} />
      </div>
      {count === 0 && (
        <Empty
          title="There are no jobs yet"
          text="It will be available here once you will start posting jobs."
          button={{ onClick: () => navigate("/job/create"), label: "Post a Job" }}
        />
      )}
      {data && !!count && (
        <PaginatedDiv
          className="!h-auto flex-1 "
          onLimit={fetch}
          loading={fetching}
          count={count}
          currentCount={data?.length}
        >
          <Table
            data={data}
            heads={[
              "Job Title",
              "Location",
              "Salary Range",
              "Recruiter",
              "Invited",
              "Interviewed",
              "Action"
            ]}
            row={(j) => {
              const {
                slug,
                job_title,
                city,
                Poster,
                _passed_interviews,
                _failed_interviews,
                _invited_interviews
              } = j as Job;
              const job = new JobClass(j);
              return [
                <p className="w-[121px] overflow-hidden text-ellipsis">{job_title}</p>,
                <p className="w-[107px] overflow-hidden text-ellipsis">{city}</p>,
                job.salaryRangeText(),
                <InitialsLogo user={Poster.User} />,
                <p>{_invited_interviews}</p>,
                <p>{_passed_interviews + _failed_interviews}</p>,

                <Button
                  onClick={() => navigate("/job/" + slug)}
                  className="!text-sm !rounded-3xl !w-[120px] !px-4"
                >
                  View Details
                </Button>
              ];
            }}
          />
        </PaginatedDiv>
      )}
    </Layout>
  );
};

export default JobsPage;
