import { useRef } from "react";
import Spinner from "./Spinner";

interface IPaginatedDiv {
  className?: string;
  children?: any;
  onLimit?: () => void; // Funtion to call when we reach scroll limit
  count?: number; // Max amount of items to paginate
  currentCount?: number; // currentAmount of items fetched
  loading?: boolean;
  "data-cy"?: string;
}

const PaginatedDiv = ({
  className = "",
  children,
  count = 0,
  currentCount = 0,
  loading,
  onLimit = () => null,
  ...props
}: IPaginatedDiv) => {
  const ref = useRef(null);

  const handleScroll = () => {
    if (!ref.current) return;
    if (currentCount >= count || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (scrollTop + clientHeight > scrollHeight - 200) onLimit();
  };

  return (
    <div
      data-cy={props["data-cy"]}
      className={"relative overflow-x-hidden overflow-y-scroll h-full " + className}
      onScroll={handleScroll}
      ref={ref}
    >
      {loading && (
        <div className="sticky top-20 h-0 overflow-visible  left-1/2  opacity-50">
          <Spinner className="w-10 h-10  " />
        </div>
      )}
      {children}
    </div>
  );
};

export default PaginatedDiv;
