import { Interview } from "src/types";
import Conversation from "../components/Conversation";
import { ReactComponent as NoComment } from "src/assets/svg/no-communication.svg";

const Recording = ({ interview }: { interview: Interview }) => {
  const { transcript } = interview;
  if (!transcript)
    return (
      <div className="mx-auto flex flex-col items-center gap-6 pt-[120px]">
        <NoComment className="w-[220px] h-[220px]" />
        <p className="font-poppins text-xl font-semibold">No Resume Found</p>
      </div>
    );

  return (
    <div className="mt-9">
      {interview.transcript && (
        <div>
          <Conversation transcript={interview.transcript} />
        </div>
      )}
    </div>
  );
};

export default Recording;
