import { getCountries, getCountryCallingCode } from "react-phone-number-input";

// Most popular dial_codes should be on top
const _order = {
  IN: 5,
  PK: 4,
  US: 3,
  NG: 2,
  MX: 1
};

export const dialCodes = getCountries()
  .map((country, index) => {
    const code = `+${getCountryCallingCode(country)}`;
    return {
      id: index,
      value: `${country} ${code}`,
      code,
      country_code: country
    };
  })
  .sort((a, b) => {
    // Put the most popular dial_codes at the start
    const [orderA, orderB] = [_order[a.country_code], _order[b.country_code]];
    if (orderA || orderB) {
      return (orderA || 0) < (orderB || 0) ? 1 : -1;
    }
    // Else sort by alfb order
    return a.value < b.value ? -1 : 1;
  });
dialCodes.forEach((d, index) => {
  d.id = index;
});
