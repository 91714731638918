import { ClientInvite } from "./../../types/models/ClientInvite";

export class ClientInviteClass {
  invite: ClientInvite;
  constructor(data: ClientInvite) {
    this.invite = data;
  }

  getStatus(): "Active" | "Invited" {
    const { User, company_id } = this.invite;
    if (User?.Client?.company_id === company_id) return "Active";
    return "Invited";
  }
}
