import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { dialCodeOptions, usDialCodeOption } from "src/components/Form/DropdownSelect/Options";
import { FormInput } from "src/components/Form/Input/FormInput";
import { FormPhoneInput } from "src/components/Form/PhoneInput/FormPhoneInput";
import { IPhone } from "src/components/Form/PhoneInput/PhoneInput";
import LoaderPage from "src/components/Loader/LoaderPage";
import useLoader from "src/hooks/useLoader";
import useSoftSearchParam from "src/hooks/utils/useSoftSearchParam";
import { updateCompany } from "src/network/company";
import { updateUser } from "src/network/user";
import { FormTag } from "src/pages/job/components/FormTag";
import { setClient } from "src/redux/slices/client";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import Client from "src/types/models/Client";
import { noSpaceAtStartNorEndRegex } from "src/utils/constraints";

const Profile = () => {
  const [params] = useSearchParams();
  const editing = params.get("edit")?.includes("true");
  return editing ? <Edit /> : <Read />;
};

// --- READ ----
const Field = ({ label, children }) => (
  <div className="w-full max-w-[500px]">
    <p className="font-poppins text-base font-semibold">{label}</p>
    <p className="font-poppins text-sm mt-[25px]">{children}</p>
  </div>
);

const Read = () => {
  const client = useAppSelector((r) => r.client.client);
  if (!client) return <LoaderPage />;

  return (
    <div className="grid grid-cols-2 gap-y-[64px] mt-10">
      <Field label="First Name">{client.User.first_name}</Field>
      <Field label="Last Name">{client.User.last_name}</Field>
      <Field label="Email">{client.User.email_id}</Field>
      <Field label="Company Name">{client.Company.name}</Field>
      <Field label="Phone Number">{client.User.full_phone}</Field>
    </div>
  );
};

// --- EDIT VIEW ---
const Edit = () => {
  const dispatch = useAppDispatch();
  const { softSetSearchParam } = useSoftSearchParam();
  const client = useAppSelector((r) => r.client.client);
  if (!client) return <LoaderPage />;
  const { is_admin } = client;
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({ defaultValues: clientToForm(client) });
  const base = { errors, control };
  const onSubmit = async (data: ClientForm) => {
    const { company_name } = data;
    softSetSearchParam("edit", "_true"); // Tell the edit button we loading
    // If company name did not change we ended
    const _client = { ...client };
    try {
      if (client.Company?.name !== company_name) {
        const company = await updateCompany({ name: company_name });
        _client.Company = company;
      }
    } catch (e) {
      console.log(e);
      throw new Error("Company name already taken");
    }
    try {
      const user = await updateUser({
        first_name: data.first_name,
        last_name: data.last_name,
        dial_code: data.phone.dial?.value,
        phone_number: data.phone.phone
      });
      _client.User = user;
    } catch (e) {
      throw new Error("Error updating user");
    }
    await dispatch(setClient(_client));
  };
  const { ldng } = useLoader(onSubmit, {
    successCallback: () => softSetSearchParam("edit", false),
    errorCallback: () => softSetSearchParam("edit", true),
    successMsg: "Data udpated"
  });

  return (
    <form id="client_form" onSubmit={handleSubmit(ldng)}>
      <div className="grid grid-cols-2 gap-12 w-fit">
        <WrapForm>
          <FormTag>First Name</FormTag>
          <FormInput
            {...base}
            name="first_name"
            input={{
              placeholder: "Enter your first name"
            }}
            rules={{
              required: "Field is required",
              minLength: {
                value: 2,
                message: "First name must be at least 2 characters"
              },
              validate: (value) =>
                noSpaceAtStartNorEndRegex.test(value) || "Invalid whitespace usage"
            }}
          />
        </WrapForm>

        <WrapForm>
          <FormTag>Last Name</FormTag>
          <FormInput
            {...base}
            name="last_name"
            input={{
              placeholder: "Enter your last name"
            }}
            rules={{
              required: "Field is required",
              minLength: {
                value: 2,
                message: "Last name must be at least 2 characters"
              },
              validate: (value) =>
                noSpaceAtStartNorEndRegex.test(value) || "Invalid whitespace usage"
            }}
          />
        </WrapForm>

        {is_admin && (
          <WrapForm>
            <FormTag>Company Name</FormTag>
            <FormInput
              {...base}
              name="company_name"
              input={{
                placeholder: "Enter company name"
              }}
              rules={{
                required: "Name is required",
                validate: (value) =>
                  noSpaceAtStartNorEndRegex.test(value) || "Invalid white space usage"
              }}
            />
          </WrapForm>
        )}
        <WrapForm>
          <FormTag>Phone Number</FormTag>
          <FormPhoneInput {...base} required name="phone" className="mb-6" />
        </WrapForm>
      </div>
    </form>
  );
};

const WrapForm = ({ children }) => <div className="w-[500px]">{children}</div>;

const clientToForm = ({ User, Company }: Client) => ({
  first_name: User.first_name,
  last_name: User.last_name,
  company_name: Company.name,
  phone: {
    phone: User.phone_number,
    dial: dialCodeOptions.find((d) => User.dial_code === d.value) || usDialCodeOption
  } as IPhone
});

type ClientForm = ReturnType<typeof clientToForm>;

export default Profile;
