import { FormEmailInput } from "src/components/Form/EmailInput/FormEmailInput";
import Layout from "../components/Layout";
import { useForm } from "react-hook-form";
import Button from "src/components/Button";
import { isEmpty } from "src/utils/object";
import { isWorkEmail } from "src/utils/constraints";
import { Link, useNavigate } from "react-router-dom";
import { sendMagicLink } from "src/network/auth";
import useLoader from "src/hooks/useLoader";

const LoginPage = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { email: "" } });
  const sendLink = async ({ email }) => {
    try {
      await sendMagicLink(email);
      navigate("/linksent", { state: { email } });
    } catch (e: any) {
      const message = e?.message;
      if (message === "Unverified") navigate("/signup/confirm", { state: { email } });
      throw new Error(message);
    }
  };
  const { loading, ldng } = useLoader(sendLink, { successMsg: "Link sent" });

  return (
    <Layout>
      <form onSubmit={handleSubmit(ldng)} className="w-[588px] pt-[46px]">
        <p className="font-poppins text-2xl font-bold">Login to Outdefine</p>
        <p className="text-base font-poppins mt-[10px] mb-9">
          Don’t have any account?{" "}
          <Link className=" text-odfblue underline font-semibold" to="/signup">
            Signup
          </Link>
        </p>

        <p className="font-poppins text-base font-semibold mb-3">Company Email*</p>
        <FormEmailInput
          name="email"
          errors={errors}
          control={control}
          rules={{
            required: "Email is required",
            validate: (value) => isWorkEmail(value) || "Please use your company email"
          }}
        />

        <Button
          loading={loading}
          type="submit"
          disabled={!isEmpty(errors)}
          className="mt-9 !rounded-3xl"
        >
          Send link to Login
        </Button>
      </form>
    </Layout>
  );
};

export default LoginPage;
