import { ReactComponent as CopyIcon } from "src/assets/svg/copy.svg";
import { customToast } from "src/utils/toast";

const CopyButton = ({ children = <CopyIcon className="w-6 h-6" />, text, className = "" }) => {
  return (
    <button onClick={() => copy(text)} type="button" className={className}>
      {children}
    </button>
  );
};

function copy(text: string) {
  // Copy the text inside the text field
  navigator.clipboard.writeText(text);

  // Alert the copied text
  customToast({ type: "success", message: "Copied" });
}

export default CopyButton;
