import { useEffect, useState } from "react";

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactElement;
  value?: string;
}

const Input = ({ value, onChange, className, icon, ...props }: IInput) => {
  const [_value, setValue] = useState(value || "");

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  // Allow to use a default value, since `value` is initialized as an empty string
  useEffect(() => {
    if (value) onChange?.({ target: { value } } as any);
  }, []);

  return (
    <div className="relative w-auto font-poppins text-sm">
      <input
        className={`w-full h-12 border-[1px] bg-white border-dark-gray outline-none focus:border-odf hover:border-odf rounded-lg px-4 py-2 
      ${className}`}
        value={_value}
        onChange={_onChange}
        {...props}
      />
    </div>
  );
};

export default Input;
