import Button from "src/components/Button";
import Layout from "../components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import useLoader from "src/hooks/useLoader";
import { sendMagicLink } from "src/network/auth";

const LinkSentPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { email } = state;
  const { loading, ldng } = useLoader(sendMagicLink, { successMsg: "Link sent" });

  return (
    <Layout>
      <div className="w-[588px] pt-[46px]">
        <p className="font-poppins font-bold text-2xl">Login to Outdefine</p>
        <p className="p-6 my-9 rounded-xl bg-lighter-gray text-inactive-gray">
          A link has been sent to <span className="font-bold text-odfblue">{email}</span>to login to
          your account. Click on that link to get started.
        </p>
        <p className="text-inactive-gray font-poppins text-base mb-9 text-center">
          Haven’t received any link yet?
        </p>
        <Button loading={loading} onClick={() => ldng(email)} className="!rounded-3xl">
          Resend Link
        </Button>
        <Button
          disabled={loading}
          onClick={() => navigate("/")}
          variant="white_blue"
          className="!rounded-3xl mt-5"
        >
          Back
        </Button>
      </div>
    </Layout>
  );
};

export default LinkSentPage;
