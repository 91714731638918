import Chart from "react-apexcharts";

import { ReactComponent as Briefcase } from "src/assets/svg/briefcase2.svg";
import { ReactComponent as DirectorBox } from "src/assets/svg/directorbox-notif.svg";
import { ReactComponent as Calendar } from "src/assets/svg/calendar.svg";
import { Interview } from "src/types";
import Tag from "../components/Tag";
import { dateFormat1 } from "src/utils/dates/formats";
import { Feedback } from "src/classes/models/interview";
import Wrapper from "../components/Wrapper";
import FeedbackCard from "../components/FeedbackCard";
import SkillsFeedback from "../components/SkillsFeedback";

const Report = ({ interview, feedback }: { interview: Interview; feedback: Feedback }) => {
  const { general, personality, resume, skill, overall, skills } = feedback;
  return (
    <div className="max-w-[1110px] mx-auto mt-10">
      <div className="flex gap-[93px]">
        {/* Tags */}
        <div className="flex flex-wrap gap-y-9 gap-x-[44px] w-[524px] h-fit">
          <Tag>
            <Briefcase className="w-6 h-6" />
            {interview.Job.job_title}
          </Tag>
          <Tag>
            <DirectorBox className="w-6 h-6" />
            {interview.email}
          </Tag>
          {interview.date_completed && (
            <Tag>
              <Calendar className="w-6 h-6" />
              {dateFormat1(interview.date_completed)}
            </Tag>
          )}
        </div>
        {/* Overview */}
        <div className="bg-white rounded-3xl border-[1px] border-odfblue border-opacity-65 w-[270px] h-[208px] p-6 pb-0 ml-auto">
          <p className="font-poppins text-2xl font-semibold w-fit mb-2">Overall Hire Score</p>
          <div>
            <Chart
              type="radialBar"
              width="100%"
              height="85%"
              series={[overall._score.number]}
              options={{
                chart: { type: "radialBar" },
                fill: { colors: [overall._score.style.color] },
                labels: [overall.score],
                plotOptions: {
                  radialBar: {
                    dataLabels: {
                      name: {
                        show: true,
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: "36px",
                        fontWeight: 600,
                        offsetY: 12
                      },
                      value: {
                        show: false
                      }
                    },
                    hollow: {
                      size: "60%"
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
      {/* Overall */}
      <p className="font-poppins font-bold text-base my-4">Overall Feedback</p>
      <Wrapper>{overall.summary}</Wrapper>

      {/* Grid */}
      <div className="grid grid-cols-2 gap-9 mt-9">
        <FeedbackCard feedback={general} />
        <FeedbackCard feedback={resume} />
        <FeedbackCard feedback={skill} />
        <FeedbackCard feedback={personality} />
      </div>

      {/* Skills feedback */}
      <SkillsFeedback feedback={skills} />
    </div>
  );
};

export default Report;
