import { useSearchParams } from "react-router-dom";

const useSoftSearchParam = () => {
  const [, setSearchParam] = useSearchParams();
  const softSetSearchParam = (field: string, value: any) => {
    setSearchParam((prev) => {
      prev.set(field, String(value));
      return prev;
    });
  };
  return { softSetSearchParam };
};

export default useSoftSearchParam;
