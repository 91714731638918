import { useSearchParams } from "react-router-dom";
import { JobClass } from "src/classes/models/job";
import Job from "src/types/models/Job";
import { ReactComponent as CheckIcon } from "src/assets/svg/check2.svg";
import { useState } from "react";
import { JobSkillType } from "src/types";
import { UpdateJobParams } from "src/network/job/type";

type Skill = Job["Skills"][0];

const Skills = ({ job, onUpdate }: { job: Job; onUpdate: (params: UpdateJobParams) => void }) => {
  const [params] = useSearchParams();
  const editing = params.get("edit")?.includes("true");
  const j = new JobClass(job);
  return (
    <div className="p-9 w-full rounded-2xl border-[1px] border-odfblue-hue bg-white">
      {editing ? <Edit onUpdate={onUpdate} j={j} /> : <Read j={j} />}
    </div>
  );
};

// --- READ ---

const Read = ({ j }: { j: JobClass }) => {
  return (
    <>
      <Label>Technical Skills</Label>
      <SkillTags skills={j.technicalSkills()} />
      <div className="mt-12" />
      <Label>Intercultural Skills</Label>
      <SkillTags skills={j.interculturalSkills()} />
    </>
  );
};
const Label = ({ children }) => <p className="font-poppins font-semibold text-base">{children}</p>;
const SkillTag = ({ skill }: { skill: Skill }) => (
  <p
    className={`font-poppinst text-sm p-4 rounded-lg bg-[#F9F9F9]  ${skill.hire_job_skill.for_interview && " border-[1px] border-odfblue text-odfblue font-semibold"}`}
  >
    {skill.name}
  </p>
);
const SkillTags = ({ skills }: { skills: Skill[] }) => (
  <div className="flex flex-wrap gap-3 mt-4">
    {skills.map((s) => (
      <SkillTag skill={s} key={s.name} />
    ))}
  </div>
);

// --- EDIT ---
type ParamSkill = {
  for_interview: boolean;
  type: JobSkillType;
  id: number;
  name: string;
};
const Edit = ({ j, onUpdate }: { j: JobClass; onUpdate: (params: UpdateJobParams) => void }) => {
  const [skills, setSkills] = useState<ParamSkill[]>(
    j.job.Skills.map((s) => ({
      id: s.id,
      for_interview: s.hire_job_skill.for_interview,
      type: s.hire_job_skill.type,
      name: s.name
    }))
  );
  const onMark = (id: number) => {
    const skill = skills.find((s) => s.id === id);
    if (!skill) return;
    skill.for_interview = !skill.for_interview;
    setSkills([...skills]);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onUpdate({
      skills: skills.map((s) => ({ skill_id: s.id, for_interview: s.for_interview }))
    });
  };

  return (
    <form id="update_job" onSubmit={onSubmit}>
      <Label>Technical Skills</Label>
      <SkillInputs onMark={onMark} skills={skills.filter((s) => s.type === "Technical")} />
      <div className="mt-12" />
      <Label>Intercultural Skills</Label>
      <SkillInputs onMark={onMark} skills={skills.filter((s) => s.type === "Intercultural")} />
    </form>
  );
};
const SkillInputs = ({ skills, onMark }: { skills: ParamSkill[]; onMark: (s: number) => void }) => (
  <div className="flex gap-3 flex-wrap mt-6">
    {skills.map((s) => (
      <button
        type="button"
        onClick={() => onMark(s.id)}
        key={s.id}
        className={`p-4 bg-lighter-gray flex flex-wrap gap-2 rounded-lg font-poppins text-sm  items-center 
           ${s.for_interview ? "text-odfblue border-[1px]  border-odfblue" : "text-black"} `}
      >
        {s.name}
        {s.for_interview && <CheckIcon className="w-4 h-4 fill-odfblue " />}
      </button>
    ))}
  </div>
);

export default Skills;
