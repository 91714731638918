import { useEffect, useState } from "react";

export interface ICheckbox {
  value?: boolean;
  className?: string;
  wrapperClassName?: string;
  onChange?: (b: boolean) => void;
  title?: string;
  style?: 0 | 1;
}

const Checkbox = ({
  value,
  onChange,
  className,
  wrapperClassName,
  title,
  style = 0
}: ICheckbox) => {
  const [_value, setValue] = useState(value || false);

  const _onChange = () => {
    const b = !_value;
    setValue(b);
    onChange?.(b);
  };

  // If the value is externally alterated keep it in sync
  useEffect(() => {
    if (value !== undefined && value !== _value) setValue(!_value);
  }, [value]);

  const Wrapper = Wrappers[style];

  return (
    <button className={wrapperClassName} type="button" onClick={_onChange}>
      <Wrapper title={title || ""} className={className} checked={_value} />
    </button>
  );
};

// We can implement different wrappers

interface IWrapper {
  className?: string;
  checked: boolean;
}

interface IWrapper1 extends IWrapper {
  title: string;
}

const Wrapper0 = ({ checked }: IWrapper1) =>
  checked ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M16.1971 2H7.8129C4.17108 2 2 4.17 2 7.81V16.18C2 19.83 4.17108 22 7.8129 22H16.1871C19.8289 22 22 19.83 22 16.19V7.81C22.01 4.17 19.8389 2 16.1971 2Z"
        fill="#5F5FFF"
      />
      <path
        d="M10.5799 15.5796C10.3799 15.5796 10.1899 15.4996 10.0499 15.3596L7.21994 12.5296C6.92994 12.2396 6.92994 11.7596 7.21994 11.4696C7.50994 11.1796 7.98994 11.1796 8.27994 11.4696L10.5799 13.7696L15.7199 8.62961C16.0099 8.33961 16.4899 8.33961 16.7799 8.62961C17.0699 8.91961 17.0699 9.39961 16.7799 9.68961L11.1099 15.3596C10.9699 15.4996 10.7799 15.5796 10.5799 15.5796Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M16.1971 2H7.8129C4.17108 2 2 4.17 2 7.81V16.18C2 19.83 4.17108 22 7.8129 22H16.1871C19.8289 22 22 19.83 22 16.19V7.81C22.01 4.17 19.8389 2 16.1971 2Z"
        stroke="#CCCCCC"
      />
    </svg>
  );

const Wrapper1 = ({ checked, title, className }: IWrapper1) => (
  <div
    className={`relative font-poppins font-[500] text-sm flex justify-between items-center p-4 w-full h-12 border-[1px] rounded-lg 
  ${checked ? "border-odfblue text-odfblue " : "border-[#ccc] text-black "} ${className}`}
  >
    {title}
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        className={
          checked ? "fill-odfblue" : "border-[1px] rounded-[4px] border-inactive-gray fill-white"
        }
      >
        <path d="M10.794 1.33203H5.20732C2.78065 1.33203 1.33398 2.7787 1.33398 5.20536V10.7854C1.33398 13.2187 2.78065 14.6654 5.20732 14.6654H10.7873C13.214 14.6654 14.6607 13.2187 14.6607 10.792V5.20536C14.6673 2.7787 13.2207 1.33203 10.794 1.33203ZM11.1873 6.46536L7.40732 10.2454C7.31398 10.3387 7.18732 10.392 7.05398 10.392C6.92065 10.392 6.79398 10.3387 6.70065 10.2454L4.81398 8.3587C4.62065 8.16536 4.62065 7.84536 4.81398 7.65203C5.00732 7.4587 5.32732 7.4587 5.52065 7.65203L7.05398 9.18536L10.4807 5.7587C10.674 5.56536 10.994 5.56536 11.1873 5.7587C11.3807 5.95203 11.3807 6.26536 11.1873 6.46536Z" />
      </svg>
    </div>
  </div>
);

const Wrappers = [Wrapper0, Wrapper1];

export default Checkbox;
