import { Currency, Period } from "src/types";
import { useState } from "react";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import { currencyOptionsMap, periodOptionsMap } from "../DropdownSelect/Options";
import RangeBoxes, { IRangeBoxes } from "../RangeBoxes/RangeBoxes";

export interface Salary {
  salary?: [number | undefined, number | undefined];
  period: Period;
  currency: Currency;
}
export interface ISalaryRange extends Pick<IRangeBoxes, "input1" | "input2"> {
  value?: Salary;
  onChange?: (s: Salary) => void;
  className?: string;
}

const def: Salary = {
  salary: [10, 20],
  period: "Hourly",
  currency: "USD"
};

// For the dropdowns
const classNames = {
  indicatorSeparator: () => "hidden",
  dropdownIndicator: () => " !pl-0",
  singleValue: () => " !text-clip "
};

const SalaryRange = ({ value, onChange, className, input1, input2 }: ISalaryRange) => {
  const [_value, setValue] = useState<Salary>(value || def);

  const _onChange = (s: Salary) => {
    setValue(s);
    onChange?.(s);
  };
  const { currency, period } = _value;

  return (
    <div className={"flex flex-col gap-3 w-full " + className}>
      <div className="flex items-center z-10">
        <p className="font-poppins text-base font-semibold">Salary Range(Optional)</p>
        <DropdownSelect
          value={currencyOptionsMap[currency]}
          onChange={(o) => _onChange({ ..._value, currency: o.value as Currency })}
          className="ml-auto "
          classNames={classNames}
          controlClassName="!h-9"
          _options="currencies"
        />
        <DropdownSelect
          value={periodOptionsMap[period]}
          onChange={(o) => _onChange({ ..._value, period: o.value as Period })}
          className="ml-6"
          classNames={classNames}
          controlClassName="!h-9"
          _options="periods"
        />
      </div>

      <RangeBoxes
        input1={{ min: 1, ...input1 }}
        input2={{ min: 1, ...input2 }}
        onChange={(r) => _onChange({ ..._value, salary: r })}
        value={_value.salary}
      />
    </div>
  );
};

export default SalaryRange;
