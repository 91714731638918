import FormController, { IFormController } from "../FormController";
import Input, { IInput } from "./Input";

interface IFormInput extends IFormController {
  input?: IInput;
}

export const FormInput = ({ input, ...props }: IFormInput) => {
  return <FormController Children={Input} childrenProps={input} {...props} />;
};
