import Loader from ".";

interface ILoaderPage {
  className?: string;
}

const LoaderPage = ({ className = "" }: ILoaderPage) => {
  return (
    <div className={"w-full h-full flex items-center justify-center " + className}>
      <Loader />
    </div>
  );
};

export default LoaderPage;
