import Chart from "react-apexcharts";
import { FeedbackData } from "src/classes/models/interview";
import Wrapper from "./Wrapper";

interface ISkillsFeedback {
  feedback: FeedbackData[];
}

const SkillsFeedback = ({ feedback }: ISkillsFeedback) => {
  if (!feedback?.length) return null;
  return (
    <div>
      <p className="font-poppins font-bold text-base mt-12">Skill Set Based</p>
      {feedback.map((skill) => (
        <Wrapper className="flex justify-between mt-3 items-center" key={skill.title}>
          <div>
            <p className="font-poppins text-base font-semibold">• {skill.title}</p>
            <p className="font-poppins text-sm text-[#8A8A8A] font-[500] mt-[10px]">
              {skill.summary}
            </p>
          </div>
          <Chart
            type="radialBar"
            width={100}
            height={100}
            series={[skill._score.number]}
            options={{
              labels: [skill.score],
              chart: { type: "radialBar" },
              fill: { colors: [skill._score.style.color] },
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      show: true,
                      fontFamily: "Poppins",
                      color: "black",
                      fontSize: "24px",
                      fontWeight: 600,
                      offsetY: 7
                    },
                    value: {
                      show: false
                    }
                  },
                  hollow: {
                    size: "50%"
                  }
                }
              }
            }}
          />
        </Wrapper>
      ))}
    </div>
  );
};

export default SkillsFeedback;
