const Wrapper = ({ children, className = "" }) => (
  <div
    className={
      "w-full rounded-lg bg-white p-4 font-poppins text-sm whitespace-pre-line " + className
    }
  >
    {children}
  </div>
);

export default Wrapper;
