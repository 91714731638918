import { FormInput } from "src/components/Form/Input/FormInput";

import { IStep } from ".";
import { FormTag } from "../components/FormTag";
import { FormDropdownSelect } from "src/components/Form/DropdownSelect/FormDropdownSelect";
import { FormCheckboxSelect } from "src/components/Form/Checkbox/FormCheckboxSelect";
import { FormCityInput } from "src/components/Form/CityInput/FormCityInput";
import { FormTextArea } from "src/components/Form/TextArea/FormTextArea";
import { FormSalaryRange } from "src/components/Form/SalaryRange/FormSalaryRange";
import {
  INTERVIEW_DURATION_OPTIONS,
  JOBTERM_OPTIONS,
  LOCATIONTYPE_OPTIONS,
  YES_NO_OPTIONS
} from "src/constants/formOptions";
import { FormRangeBoxes } from "src/components/Form/RangeBoxes/FormRangeBoxes";

const Step1 = ({ form }: IStep) => {
  const {
    control,
    formState: { errors }
  } = form;

  const base = { errors, control };

  return (
    <>
      <div className="flex gap-10 mb-12">
        {/* Left */}
        <div className="w-full">
          <FormTag>Job Title*</FormTag>
          <FormInput {...base} rules={{ required: "Title is required" }} name="title" />

          <FormTag className="mt-12 !mb-3">Years of experience*</FormTag>
          <FormRangeBoxes name="year_experience" {...base} rules={{ min: 1, max: 12 }} />

          <FormTag className="mt-12">Employment Type*</FormTag>
          <FormCheckboxSelect
            {...base}
            name="location_type"
            rules={{ required: "Location type is required" }}
            select={{ boxes: LOCATIONTYPE_OPTIONS, checkboxClassName: "!w-[136px]" }}
          />

          <FormTag className="mt-12">Visa Sponsorship provided?</FormTag>
          <FormCheckboxSelect
            {...base}
            name="visa_sponsor"
            rules={{ required: "Required" }}
            select={{ boxes: YES_NO_OPTIONS, checkboxClassName: "!w-[200px]" }}
          />

          <FormTag className="mt-12">Interview Duration*</FormTag>
          <FormCheckboxSelect
            {...base}
            name="interview_duration"
            rules={{ required: "Duration is required" }}
            select={{
              boxes: INTERVIEW_DURATION_OPTIONS,
              checkboxClassName: "!w-[136px]"
            }}
          />
        </div>
        {/* Right */}
        <div className="w-full">
          <FormTag>Experience Level*</FormTag>
          <FormDropdownSelect
            {...base}
            select={{
              _options: "experiences"
            }}
            rules={{ required: "Experience is required" }}
            className="mb-12"
            name="experience_level"
          />

          <FormTag>Working Hours*</FormTag>
          <FormCheckboxSelect
            {...base}
            rules={{ required: "Working hours is required" }}
            name="term"
            select={{ boxes: JOBTERM_OPTIONS, checkboxClassName: "!w-[200px]" }}
          />

          <FormTag className="mt-12">Location*</FormTag>
          <FormCityInput {...base} rules={{ required: "Location is required" }} name="city" />

          <FormSalaryRange {...base} className="mt-12" name="salary" />
        </div>
      </div>
      <FormTag>Job Description*</FormTag>
      <FormTextArea
        errors={errors}
        area={{
          className: "p-4 h-[300px]",
          placeholder:
            "Provide a thorough and in-depth job description covering all aspects relevant to the role. The more details you provide the easier it is for Debbie to personalize skills and interviews relevant to the job."
        }}
        rules={{ required: "Description is required" }}
        name="description"
        control={control}
      />
    </>
  );
};

export default Step1;
