import Job from "src/types/models/Job";
import { get, post, put } from "../utils";
import { createJobParams, paginatedJobParams, UpdateJobParams } from "./type";
import { PaginationResults } from "src/types";

export async function createJob(body: createJobParams): Promise<Job> {
  return (await post({ path: "outdefine_hire/job", body })).body?.job;
}

export async function getJobsPaginated(
  queryParams: paginatedJobParams
): Promise<PaginationResults<Job>> {
  return (await get({ path: "outdefine_hire/job", queryParams })).body;
}

export async function getJob(queryParams: paginatedJobParams): Promise<Job | undefined> {
  return (await get({ path: "outdefine_hire/job", queryParams })).body?.data[0];
}

export async function updateJob(body: UpdateJobParams): Promise<Job> {
  return (await put({ path: "outdefine_hire/job", body })).body?.job;
}
