import FormController, { IFormController } from "../FormController";
import CheckboxSelect, { ICheckboxSelect } from "./CheckboxSelect";

interface IFormCheckboxSelect extends IFormController {
  select?: ICheckboxSelect;
}

export const FormCheckboxSelect = ({ select, ...props }: IFormCheckboxSelect) => {
  return <FormController Children={CheckboxSelect} childrenProps={select} {...props} />;
};
