import { ReactNode } from "react";
import ReactDOM from "react-dom";

interface ModalType {
  children?: ReactNode;
  className?: string;
}

// Simple wrapper for the default modal behaviour
const _Modal = ({ className, children }: ModalType) => {
  return ReactDOM.createPortal(
    <div
      data-cy="modal"
      className={`fixed top-0 left-0 w-full h-screen bg-[rgba(0,0,0,0.4)] backdrop-blur-[2px] z-50 
        flex items-center justify-center overflow-y-auto
         ${className}`}
    >
      {children}
    </div>,
    document.body
  );
};

export default _Modal;
