import { NavLink, useLocation } from "react-router-dom";
import Button from "src/components/Button";
import Logo from "src/components/Logo/Logo";
import { IPath, PATHS_MAP } from "src/constants/paths";
import { ReactComponent as BriefcaseIcon } from "src/assets/svg/briefcase.svg";
import { ReactComponent as SettingIcon } from "src/assets/svg/setting.svg";
import { ReactComponent as LeftArrowIcon } from "src/assets/svg/circle-arrow-left.svg";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import Spinner from "src/components/Spinner";
import useLoader from "src/hooks/useLoader";
import { logout } from "src/redux/slices/auth";
import path from "src/types/paths";

const Sidebar = () => {
  return (
    <div className="w-[230px] h-screen pb-6 flex flex-col">
      <div className="flex flex-col px-[18px] pb-6 h-full">
        <Logo className="w-[172px] px-4" />

        {/* Profile section */}
        <ProfileSection />

        {/* path Options */}
        {<Option value={PATHS_MAP.job} />}

        {<Option className=" mt-auto " value={PATHS_MAP.settings} />}
      </div>
      {/* Logout */}
      <div className="mt-auto pt-6 px-4 border-t-[0.5px] border-[#CCC]">{<LogoutButton />}</div>
    </div>
  );
};

interface IOption {
  value: IPath;
  className?: string;
}

const Option = ({ value, className = "" }: IOption) => {
  const { path: p, label } = value;
  const path = useLocation().pathname;
  const current = path?.split("/")[1];
  // Check if is the current selected path
  const selected = current === p;
  const Icon = OptionIcons[p];

  return (
    <NavLink
      to={"/" + p}
      className={`group flex gap-[10px] items-center text-inactive-gray rounded-lg w-full h-[45px] p-3 font-poppins text-sm 
      hover:font-semibold hover:bg-odfblue hover:text-white 
      ${selected && "!bg-odfblue !text-white !font-semibold "} ${className} `}
    >
      {Icon && (
        <Icon
          className={
            " w-5 h-5 stroke-inactive-gray group-hover:stroke-white  " +
            (selected && "!stroke-white")
          }
        />
      )}
      {label}
    </NavLink>
  );
};

const LogoutButton = () => {
  const dispatch = useAppDispatch();
  const { ldng, loading } = useLoader(() => dispatch(logout()));

  return (
    <Button
      onClick={ldng}
      loading={loading}
      className="group !p-3 !justify-start "
      variant="light_red"
    >
      <LeftArrowIcon className=" group-hover:fill-[#fcf4f4] w-6 h-6 mr-5" />
      Logout
    </Button>
  );
};
const ProfileSection = () => {
  const { client } = useAppSelector((r) => r.client);
  let Content = <Spinner />;
  if (client)
    Content = (
      <>
        <p className="font-poppins text-base font-semibold">{client.User.full_name}</p>
        <p className="font-poppins text-sm mt-2">{client.Company.name}</p>
      </>
    );

  return (
    <div className="flex flex-col rounded-lg justify-center w-full h-[70px] p-2 border-[0.5px] mb-9  border-[#CCC]">
      {Content}
    </div>
  );
};

const OptionIcons: { [key in path]?: typeof BriefcaseIcon } = {
  job: BriefcaseIcon,
  settings: SettingIcon
};

export default Sidebar;
