import { countries } from "countries-list";
import { Currency, Interview, InterviewStatus, NumberOfEmployees, Period } from "src/types";
import { ExperienceLevel } from "src/types/fields/ExperienceLevel";
import { JobTerm } from "src/types/fields/JobTerm";

export const NUMBER_OF_EMPLOYEES: NumberOfEmployees[] = [
  "1-10",
  "10-50",
  "51-200",
  "201-500",
  "501-1000",
  "1000+"
];

export const EXPERIENCE_LEVELS: ExperienceLevel[] = ["Entry", "Mid", "Senior", "Director"];

export const JOB_TERMS: JobTerm[] = [
  "Full time contract",
  "Full time",
  "Freelancer",
  "Part time",
  "Internship"
];

export const PERIODS: Period[] = ["Hourly", "Weekly", "Monthly", "Yearly"];

export const CURRENCIES: Currency[] = ["EUR", "USD", "GBP", "INR"];

export const CURRENCY_SYMBOLS: { [key in Currency]: string } = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  INR: "₹"
};

export const COUNTRIES = Object.values(countries).map((item) => item.name);

export const INTERVIEW_STATTUS_LABELS: { [key in InterviewStatus]: string } = {
  INVITED: "Invited",
  FAILED: "Failed",
  PASSED: "Completed"
};
export const interviewStatus = (i: Interview) => INTERVIEW_STATTUS_LABELS[i.status];
