import { Bars } from "react-loader-spinner";

interface ILoaderProps {
  className?: string;
}

const Loader = ({ className }: ILoaderProps) => {
  return <Bars wrapperClass={"w-full h-auto mx-auto max-w-[120px] " + className} color="#5F5FFF" />;
};

export default Loader;
