import FormController, { IFormController } from "../FormController";
import RangeBoxes, { IRangeBoxes } from "./RangeBoxes";

interface IFormRangeBoxes extends IFormController {
  range?: IRangeBoxes;
}

type _range = [number, number];
const empty = (r: _range) => !r?.[0] && !r?.[1];

export const FormRangeBoxes = ({ range, rules, ...props }: IFormRangeBoxes) => {
  // Add some basic rules
  const _rules = {
    ...rules,
    validate: {
      validRange: (r: _range) => empty(r) || r?.[0] <= r?.[1] || "Invalid range",
      minimum: (r: _range) =>
        r?.[0] >= (Number(rules?.min) || -1) || `Invalid min (>${rules?.min})`,
      maximum: (r: _range) =>
        r?.[1] <= (Number(rules?.max) || Number.POSITIVE_INFINITY) ||
        `Invalid max (<${rules?.max}) `
    }
  };
  delete _rules.min;
  delete _rules.max;

  return <FormController Children={RangeBoxes} childrenProps={range} rules={_rules} {...props} />;
};
