import path from "src/types/paths";

// In the order which they will be rendered in the sidebar
export interface IPath {
  label: string;
  path: path;
  subs?: { [key in string]?: string };
}
export const PATHS_MAP: { [key in path]: IPath } = {
  job: {
    path: "job",
    label: "Jobs",
    subs: {
      create: "job/create",
      detail: "job/:id",
      invite: "job/invite/:id",
      interview_detail: "job/interview/:id"
    }
  },
  settings: {
    path: "settings",
    label: "Settings"
  }
};
