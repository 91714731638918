import { ReactElement } from "react";
import { ReactComponent as Folder } from "src/assets/svg/no-data-folder.svg";
import Button from "src/components/Button";
import { ReactComponent as Plus } from "src/assets/svg/+.svg";

interface IEmpty {
  title: string;
  text: string;
  button: { onClick: () => any; label: string; Icon?: ReactElement };
}

const Empty = ({ title, text, button }: IEmpty) => {
  const { onClick, label, Icon = <Plus className="mr-[10px] h-6 w-6 stroke-white" /> } = button;
  return (
    <div className="flex flex-col items-center max-w-[480px] mx-auto">
      <Folder className="w-[231px] h-[201px]" />
      <p className="font-poppins font-semibold text-[20px] mt-4">{title}</p>
      <p className="font-poppins text-base text-inactive-gray mt-[10px]">{text}</p>
      <Button onClick={onClick} className="mt-[45px] !w-fit !h-fit !py-4 !px-6 !rounded-3xl ">
        {Icon}
        {label}
      </Button>
    </div>
  );
};

export default Empty;
