import FormController, { IFormController } from "../FormController";
import SalaryRange, { ISalaryRange, Salary } from "./SalaryRange";

interface IFormSalaryRange extends IFormController {
  range?: ISalaryRange;
}

export const FormSalaryRange = ({ range, rules, ...props }: IFormSalaryRange) => {
  // Add some basic rules
  const _rules = {
    ...rules,
    validate: {
      // Is ok if both are undefined since the salary could be not required, in other case we want a valid range
      validRange: ({ salary: s }: Salary) => {
        const a = Number(s?.[0]);
        const b = Number(s?.[1]);
        return (isNaN(a) && isNaN(b)) || a <= b || "Invalid range (min < max)";
      }
    }
  };
  return <FormController Children={SalaryRange} childrenProps={range} rules={_rules} {...props} />;
};
