import { useForm } from "react-hook-form";
import Button from "src/components/Button";
import { FormEmailInput } from "src/components/Form/EmailInput/FormEmailInput";
import { FormInput } from "src/components/Form/Input/FormInput";
import Modal, { IModal } from "src/components/Modal/Modal";
import useLoader from "src/hooks/useLoader";
import { sendClientInvite } from "src/network/client_invite";
import { ClientInvite } from "src/types/models/ClientInvite";

interface IInviteModal extends Pick<IModal, "onChange"> {
  data?: any;
  invites: ClientInvite[];
  setInvites: (invites: ClientInvite[]) => void;
}

const defaultValues = {
  email: "",
  first_name: "",
  last_name: ""
};

const InviteModal = ({ onChange, invites, setInvites }: IInviteModal) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });
  const base = { errors, control, input: { required: true } };
  const onSubmit = async (data: typeof defaultValues) => {
    const invite = await sendClientInvite(data);
    setInvites([invite, ...invites]);
    onChange?.(false);
  };
  const { ldng, loading } = useLoader(onSubmit, {
    errorMsg: "Error occured inviting this member",
    successMsg: "Invitation sent"
  });

  return (
    <Modal open={true} onChange={onChange}>
      <form onSubmit={handleSubmit(ldng)} className="w-[452px]">
        <p className="font-poppins text-center text-[20px] font-semibold mt-6">Invite Recuiter</p>

        <p className="font-poppins text-sm mt-6 mb-3 font-[500]">First Name</p>
        <FormInput name="first_name" {...base} />

        <p className="font-poppins text-sm mt-6 mb-3 font-[500]">Last Name</p>
        <FormInput name="last_name" {...base} />

        <p className="font-poppins text-sm mt-6 mb-3 font-[500]">Email</p>
        <FormEmailInput
          name="email"
          {...base}
          rules={{
            validate: {
              notInvited: (e) => !invites.find((i) => i.email === e) || "Already invited"
            }
          }}
        />

        <Button
          loading={loading}
          type="submit"
          className="!rounded-[24px] !h-fit mx-auto mt-6 !py-4 !w-fit"
        >
          Send invite
        </Button>
      </form>
    </Modal>
  );
};

export default InviteModal;
