import { useEffect } from "react";
import ContentLayout, { IContentLayout } from "./ContentLayout";
import Sidebar from "./Sidebar";
import { deleteCookie, getCookie } from "src/utils/storage";
import { useNavigate } from "react-router-dom";

interface ILayout extends IContentLayout {
  template?: "default" | "custom";
  className?: string;
}

const Layout = ({ ...props }: ILayout) => {
  const navigate = useNavigate();
  useEffect(() => {
    // Redirect the users to the link they wanted ot go when they log in
    const redirect = getCookie("redirect_url");
    if (redirect) navigate(redirect);
    deleteCookie("redirect_url");
  }, []);

  return (
    <div className="w-screen h-screen overflow-hidden flex">
      <Sidebar />
      <div className={"flex-1 h-full "}>
        <ContentLayout {...props} />
      </div>
    </div>
  );
};

export default Layout;
