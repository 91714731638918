import { useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import Button from "src/components/Button";
import Layout from "src/router/AuthenticatedRouter/Layout";
import Step1 from "./Step1";
import { Salary } from "src/components/Form/SalaryRange/SalaryRange";
import Step2 from "./Step2";
import Step3 from "./Step3";
import useLoader from "src/hooks/useLoader";
import { createJob } from "src/network/job";
import {
  INTERVIEW_DURATION_OPTIONS,
  JOBTERM_OPTIONS,
  LOCATIONTYPE_OPTIONS,
  YES_NO_OPTIONS
} from "src/constants/formOptions";
import { useAppSelector } from "src/redux/store";
import { jobDescAnalyzer } from "src/network/debbie";
import { experienceOptions } from "src/components/Form/DropdownSelect/Options";

interface SkillInput {
  name: string;
  for_interview: boolean;
}

export const defaultValues = {
  description: "",
  title: "",
  term: undefined as (typeof JOBTERM_OPTIONS)[0] | undefined,
  year_experience: undefined as number[] | undefined,
  location_type: undefined as (typeof LOCATIONTYPE_OPTIONS)[0] | undefined,
  city: "",
  experience_level: undefined as (typeof experienceOptions)[0] | undefined,
  interview_duration: undefined as (typeof INTERVIEW_DURATION_OPTIONS)[0] | undefined,
  visa_sponsor: YES_NO_OPTIONS[1],
  salary: {
    period: "Hourly",
    currency: "USD",
    salary: undefined
  } as Salary,
  technical_skills: [] as SkillInput[],
  intercultural_skills: [] as SkillInput[],
  job_model_id: ""
};

const Steps = [Step1, Step2, Step3];

const CreateJobPage = () => {
  const [step, setStep] = useState(0);
  // Depending on the step the back buttton does something different
  let back = !step ? "/" : () => setStep(0);
  if (step === 2) back = undefined as any;
  const form = useForm({ defaultValues });
  const email = useAppSelector((r) => r.auth?.user?.email_id || "");
  const { handleSubmit, setValue } = form;
  const submit = async (data: typeof defaultValues) => {
    // Step 0 goes to step 1
    if (!step) {
      // We need to fetch skills suggestions based on description
      try {
        const { behaviour, tech, job_id } = await jobDescAnalyzer({
          email,
          description: data.description
        });
        if (!behaviour?.length) throw new Error("No beh skills");
        if (!tech?.length) throw new Error("No tech skills");
        if (!job_id) throw new Error("No job_id");

        // Haxes:
        // a) trim because gagan returns whitespaces from the endpoint :)
        // b) Romil asked to force first letter uppercase
        const hackSkillName = (name) => name.trim()[0].toUpperCase() + name.trim().slice(1);
        setValue(
          "technical_skills",
          tech.map((s, i) => ({ name: hackSkillName(s), for_interview: i < 2 }))
        );
        setValue(
          "intercultural_skills",
          behaviour.map((s, i) => ({ name: hackSkillName(s), for_interview: i < 2 }))
        );
        setValue("job_model_id", job_id);
      } catch (e) {
        console.error(e);
        throw new Error(
          "Error while analyzing job description, try to with a more accurate description"
        );
      }
      return setStep(1);
    }

    // Step 1 creates job
    const s = data.salary.salary;
    const job = {
      job_title: data.title,

      year_experience: data.year_experience as number[],
      experience_level: data.experience_level?.value,
      location_type: data.location_type?.value,
      description: data.description,
      visa_sponsor: data.visa_sponsor.value,
      city: data.city,
      term: data.term?.value,
      interview_duration: data.interview_duration?.value,
      technical_skills: data.technical_skills,
      intercultural_skills: data.intercultural_skills,
      job_model_id: data.job_model_id,
      Salary: s?.[0]
        ? {
            period: data.salary.period,
            currency: data.salary.currency,
            rate: s as any
          }
        : undefined
    };

    try {
      const j = await createJob(job);
      if (j) setStep(2);
    } catch (e) {
      console.error(e);
      throw new Error("Error occured on job creation");
    }
  };
  const { ldng: _submit, loading } = useLoader(submit);

  // The button changes depending on the step
  let Butn;
  if (step < 2)
    Butn = (
      <Button loading={loading} form="create_job" type="submit">
        {step ? "Post" : "Next"}
      </Button>
    );

  const Step = Steps[step];

  return (
    <Layout title="Post A Job" back={back} rightChildren={Butn}>
      <form
        id="create_job"
        onSubmit={handleSubmit(_submit)}
        className="bg-white min-h-full h-fit rounded-2xl border-[1px] border-light-gray p-9"
      >
        <Step form={form} />
      </form>
    </Layout>
  );
};

export interface IStep {
  form: UseFormReturn<typeof defaultValues>;
}
export default CreateJobPage;
