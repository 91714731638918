import { BACKEND_AI_URL } from "src/config/env";
import { JobDescAnalyzerParams, JobDescAnalyzerResp } from "./type";

export async function jobDescAnalyzer({
  email,
  description
}: JobDescAnalyzerParams): Promise<JobDescAnalyzerResp> {
  const reponse = await fetch(`${BACKEND_AI_URL}/interviewparser`, {
    method: "POST",
    body: JSON.stringify({ email, jd: description }),
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  });

  return (await reponse.json()).message;
}
