import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import FileSaver from "file-saver";

import { ReactComponent as DownloadIcon } from "src/assets/svg/download.svg";
import Layout from "src/router/AuthenticatedRouter/Layout";
import { getInterview } from "src/network/interview";
import { Interview } from "src/types";
import LoaderPage from "src/components/Loader/LoaderPage";
import { customToast } from "src/utils/toast";
import { JobPageTab } from "src/pages/job/detail";
import { InterviewClass } from "src/classes/models/interview";
import Tabs from "src/components/Tabs";
import Report from "./tabs/Report";
import Recording from "./tabs/Recording";
import Resume from "./tabs/Resume";
import Button from "src/components/Button";
import useSlugParam from "src/hooks/useSlug";

type tab = "Report" | "Recording" | "Resume";
const tabs: tab[] = ["Report", "Recording", "Resume"];

const InterviewDetailPage = () => {
  const { id } = useSlugParam();
  const [params] = useSearchParams();
  const tab: tab = (params.get("tab") as tab) || tabs[0];
  const [interview, setInterview] = useState<Interview>();
  const [feedback, setFeedback] = useState(InterviewClass.defaultFeedback());

  // Initial Fetch
  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const i = await getInterview({ id });
        if (!i) throw new Error();
        setInterview(i);
        const f = i.ai_feedback;
        if (!f) throw new Error();
        const I = new InterviewClass(i);
        setFeedback(I.getParsedFeedback());
      } catch {
        customToast({ message: "Feedback is currently unavailable, try again later.." });
      }
    })();
  }, [id]);

  let Right;
  switch (tab) {
    case "Resume": {
      // Also allow to download the resume with a non cryptic name
      if (!interview?.resume_presigned) break;
      const I = new InterviewClass(interview);
      const handleClick = () => FileSaver.saveAs(interview.resume_presigned, I.getResumeFileName());
      Right = (
        <Button shapeVariant={2} variant="white_blue" onClick={handleClick}>
          <DownloadIcon className="w-6 h-6 mr-2" />
          Download Resume
        </Button>
      );

      break;
    }
    default:
      break;
  }

  const Content =
    interview && feedback.general.title ? (
      <Tabs
        withSearchParam
        tabs={tabs}
        contents={[
          <Report interview={interview} feedback={feedback} />,
          <Recording interview={interview} />,
          <Resume interview={interview} />
        ]}
      />
    ) : (
      <LoaderPage />
    );

  return (
    <Layout
      rightChildren={Right}
      title={
        interview ? (
          <>
            <span className="text-odfblue">
              {interview?.first_name || ""} {interview?.last_name || ""}'s{" "}
            </span>
            Interview report for <span className="text-odfblue">{interview.Job.job_title}</span>{" "}
            role.
          </>
        ) : (
          "Loading..."
        )
      }
      back={interview && `/job/${interview?.Job.slug}?tab=${"Interviewed Talent" as JobPageTab}`}
    >
      {Content}
    </Layout>
  );
};

export default InterviewDetailPage;
