import { useParams } from "react-router-dom";
import { shallowDecipher } from "src/utils/cipher";

// This hooks takes care of deciphering slugs and providing id and slug from params
function useSlugParam() {
  const { id: slug } = useParams();
  const id = slug ? shallowDecipher(slug) : undefined;
  return { id, slug };
}

export default useSlugParam;
