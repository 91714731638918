import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CheckIcon } from "src/assets/svg/check2.svg";
import Button from "src/components/Button";

const Step2 = () => {
  const { id: slug } = useParams();
  const navigate = useNavigate();
  return (
    <div className="bg-lighter-gray p-9 rounded-[10px] border-[1px] border-[rgba(95,95,255,0.10)] flex flex-col gap-9 w-[660px] mx-auto mt-[50px]">
      <CheckIcon className="w-[60px] h-[60px]" />
      <p className="font-poppins text-2xl font-bold  ">
        All the talents have been invited to interview!
      </p>
      <p className="font-poppins text-base text-inactive-gray text-start ">
        All talents have been invited to participate in the interview process. A link to the
        interview will be provided via email. Once the interview is complete, we will share the
        interview analytics. Please stay tuned for further updates.
      </p>

      <Button onClick={() => navigate("/job/" + slug)} className="!rounded-full ">
        Okay, Got it
      </Button>
    </div>
  );
};

export default Step2;
