import Button from "src/components/Button";
import { ReactComponent as CheckIcon } from "src/assets/svg/check2.svg";
import { useNavigate } from "react-router-dom";
import { IStep } from ".";

const Step3 = ({ form }: IStep) => {
  const { watch } = form;
  const navigate = useNavigate();
  return (
    <>
      <div className="p-9 flex flex-col gap-9 bg-lighter-gray rounded-[10px] border-[1px] border-odf border-opacity-10 w-[606px] mx-auto mt-[135px]">
        <CheckIcon className="w-[60px] h-[60px]" />
        <p className="font-poppins text-2xl font-bold text-center ">Your job has been posted</p>
        <p className="font-poppins text-base text-inactive-gray text-center ">
          Your “{watch("title")}” job has been posted. You can now invite candidates to interview
          with Debbie for the role.
        </p>

        <Button onClick={() => navigate("/job")} className="w-full !rounded-full ">
          Done
        </Button>
      </div>
    </>
  );
};

export default Step3;
