import { JobSkillType, JobTerm, LocationType } from "src/types";

export const JOBTERM_OPTIONS: { value: JobTerm; label: string }[] = [
  { label: "Full Time", value: "Full time" },
  { label: "Part Time", value: "Part time" }
];

export const LOCATIONTYPE_OPTIONS: { value: LocationType; label: string }[] = [
  { label: "Onsite", value: "ONSITE" },
  { label: "Remote", value: "REMOTE" },
  { label: "Hybrid", value: "HYBRID" }
];

export const YES_NO_OPTIONS: { value: boolean; label: string }[] = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

export const INTERVIEW_DURATION_OPTIONS: { value: number; label: string }[] = [
  { label: "20 Minutes", value: 20 },
  { label: "30 Minutes", value: 30 },
  { label: "45 Minutes", value: 45 }
];

export const SKILL_TYPE_OPTIONS: { value: JobSkillType; label: string }[] = [
  { label: "Technical", value: "Technical" },
  { label: "Intercultural", value: "Intercultural" }
];
