import { post } from "../utils";
import { IClientSignUp, IMemberSignUp } from "./type";

export async function sendMagicLink(email: string): Promise<{ success?: boolean }> {
  return (
    // PATCH: add an extra retry to avoid "User not found" error the first time the user tries to login
    await post({ path: "auth", suffix: "login/magiclink", body: { email, app: "HireFrontend" }, retries: 2})
  ).body?.data;
}

export async function clientSignUp(body: IClientSignUp) {
  return (await post({ path: "outdefine_hire", suffix: "signup/client", body })).body?.data;
}

export async function clientMemberSignUp(body: IMemberSignUp) {
  return (await post({ path: "outdefine_hire", suffix: "signup/clientmember", body })).body?.data;
}
