import { Interview, PaginationResults } from "src/types";
import {
  BulkInviteParams,
  InterviewMetrics,
  InterviewMetricsParams,
  InterviewPaginatedParams,
  SendReminderParams
} from "./type";
import { post, get } from "../utils";

export async function bulkInvite(
  body: BulkInviteParams
): Promise<{ invited: Interview[]; already_invited: Interview[] }> {
  return (await post({ path: "outdefine_hire/interview", body })).body;
}

export async function getInterviewsPaginated(
  queryParams: InterviewPaginatedParams
): Promise<PaginationResults<Interview>> {
  return (await get({ path: "outdefine_hire/interview", queryParams })).body;
}

export async function getInterview(
  queryParams: InterviewPaginatedParams
): Promise<Interview | undefined> {
  return (await get({ path: "outdefine_hire/interview", queryParams })).body?.data[0];
}

export async function getInterviewMetrics(
  queryParams?: InterviewMetricsParams
): Promise<InterviewMetrics> {
  return (await get({ path: "outdefine_hire/interview/metrics", queryParams })).body.metrics;
}

export async function sendReminder(
  body: SendReminderParams
): Promise<{ invited: Interview[]; already_invited: Interview[] }> {
  return (await post({ path: "outdefine_hire/interview/reminder", body })).body;
}
