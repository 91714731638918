import User from "src/types/models/User";
import { get, post } from "../utils";

export async function getUser(email: string): Promise<User> {
  return (await get({ suffix: email, path: "user" })).body?.data as User;
}

export async function updateUser(user: Partial<User>): Promise<User> {
  return (await post({ path: "user", body: user })).body?.data as User;
}
